<template>
  <div class="card-item">
    <div class="card-item-title text-theme fs14">
      <span class="circle-dot"></span>
      <span>{{ title }}</span>
      <div class="title-btn">
        <slot name="title_append"></slot>
      </div>
    </div>
    <div class="card-item-content">
      <slot></slot>
    </div>
    <div class="card-item-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "card-item",
  props: {
    title: {
      require: true,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-item {
  background-color: #fff;
  margin-bottom: 12px;
}

.card-item-title {
  height: 54px;
  padding: 0 20px;
  border-bottom: 1px solid #ebeef5;
  line-height: 54px;
}
.title-btn {
  float: right;
  display: inline-block;
}
.circle-dot {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: $basicTextColor;
}
.card-item-content {
  padding: 20px;
}
</style>