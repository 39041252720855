import Vue from 'vue'
import App from './App.vue'
import { getUserInfo } from './api/apis'
import { setMenu } from "./utils/datautils/tempRequstData";
import { loadMenus2,loadMenus } from "./router/index" 
//element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// global css
import './assets/styles/index.scss'
//阿里图标库
import "@/assets/icons/iconfont.css"

//这些放在下面，不然局内样式会被elementui覆盖，具体是哪个文件导致的暂不清楚
import './router/index'
import router from './router/routers'

import store from './store'
import Cookies from 'js-cookie';
//全局组件
import Pagination from './components/Pagination'
import CardItem from "./components/Items/CardItem.vue";


// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('CardItem', CardItem)


// 全局数据/函数
import data_fn from './utils/datautils'
Vue.prototype.$globalData = data_fn

//axios
import axios from 'axios';
Vue.prototype.$axios = axios;

//echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

//添加全局守卫
router.beforeEach((to, from, next)=>{
  //获取token
  const token = Cookies.get('token')
  //token不存在,且不在登录页面
  if(!token && to.name!=='login'){
     //清除userInfo
     sessionStorage.removeItem('userInfo');
     //清除路由缓存
     sessionStorage.removeItem('rewriteRoutes');
     //清除菜单栏缓存
     sessionStorage.removeItem('sidebarRoutes');
    next({ name:'login'})
  }else if(token && to.name ==='login'){//如果token存在
    //验证token，并将用户数据本地储存
    // getUserInfo(token).then(res=>{
    //   console.log("69")
    //   //将用户信息转成字符串
    //   var userInfo= JSON.stringify(res)
    //   //本地存储用户信息
    //   sessionStorage.setItem("userInfo",userInfo)
    //   console.log("69")
    //   if(to.name ==='login'){//在登录页面
    //     loadMenus2(setMenu(res))
    //   }else{
    //     loadMenus(setMenu(res))
    //     next()
    //   }
    // })
    next({ name:'home'})
  }else{
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    if(sessionStorage.getItem("userInfo")!==null&&sessionStorage.getItem("userInfo")!==""){ 
      loadMenus(setMenu(JSON.parse(sessionStorage.getItem("userInfo"))))
    }else{
      // const token = Cookies.get('token')
      //  getUserInfo(token).then(res=>{
      //    //将用户信息转成字符串
      //    var userInfo = JSON.stringify(res);
      //    sessionStorage.setItem("userInfo", userInfo);
      //    loadMenus(setMenu(res))
      // })
    }
  }
}).$mount('#app')
