
// 表单处理数据
export default {
  name: "form_utils",
  //表单验证函数
  checkFormInput(rule, value, callback) {
    if (!value) {
      return callback(new Error("请填写"));
    } else {
      callback();
    }
  },
  checkFormSel(rule, value, callback) {
    if (value === "") {
      return callback(new Error("请选择"));
    } else {
      callback();
    }
  },
  checkMoney(rule, value, callback) {
    let reg = /^([0-9]+(\.[0-9]{1,2})?)?$/;
    // let reg = /^([0-9]+(\.[0-9]+)?)?$/;
    // if (!value) {
    //   return callback(new Error("金额不能为空"));
    // }
    setTimeout(() => {
      if (value != null) {
        if (!reg.test(value)) {
          callback(new Error("请填写有效数据"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }, 300);
  },
  // 可不写的数值
  checkNumber(rule, value, callback) {
    let reg = /^(\d+)?$/;
    // let reg = /^[1-9]*$/
    // ^[1-9]\d*$ 或 ^([1-9][0-9]*){1,3}$ 或 ^\+?[1-9][0-9]*$

    // if (!value) {
    //   callback();
    // }
    // if (value === "") {
    //   return callback(new Error("请填写"));
    // } else {
    //   setTimeout(() => {
    //   if (!reg.test(value)) {
    //     // !Number.isInteger(value)
    //     callback(new Error("请输入一个正整数"));
    //   } else {
    //     callback();
    //   }
    // }, 300);
    // }

    setTimeout(() => {
      if (value !== null) {
        if (!reg.test(value)) {
          // !Number.isInteger(value)
          callback(new Error("请输入数值"));
        } else {
          callback();
        }
      } else {
        callback();

      }

    }, 300);
  },

  //时间选择器快捷选项
}

