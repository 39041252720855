<template>
  <div class="head-nav">
    <div class="head-container">
      <div class="logo-container"  @click="proxyImage" style="cursor:pointer;">
        <slot name="left">
            <img id="left" slot="left" src="../../assets/images/jk-logo.png">
        </slot>
      </div>
      <div class="setting-container">
        <div class="msg-btn"  @click="tomessagelist()"> 
          <el-badge is-dot :hidden="messageshow">
            <i class="el-icon-message-solid"></i>
          </el-badge>
        </div>
        <div class="user-info">
          <el-dropdown
            class="setting-dropdown"
            trigger="click"
            @command="handleCommand"
          >
            <div class="userinfobox">
              <span class="usernamebox">{{ userInfo.Name }}</span>
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="password">
                <div>修改密码</div>
              </el-dropdown-item> -->
              <el-dropdown-item  class="logout" command="logout">
                <div>退出登录</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div>
      <el-dialog :visible.sync="logoutVisible"  width="400px">
        <span slot="title" class="dialog-title">
          <i
            class="el-icon-warning"
            style="color: #409eff; font-size: 18px"
          ></i>
          <span style="margin: 0 10px; color: #000">温馨提示</span>
        </span>
        <div class="logout-text">您确定要退出登陆吗？退出后需要重新获取验证码！</div>
        <span slot="footer" class="dialog-footer">
          <div class="logout-btn">
            <el-button size="medium" @click="logoutVisible = false"
              >取消</el-button>
            <el-button type="primary" @click="logoutBtn" size="medium"
              >退出登录</el-button>
          </div>
        </span>
      </el-dialog>      
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {getUserInfo,GetPushUserCheckList } from "../../api/apis";
export default {
  data() {
    return {
      messageshow:true,
      name: "",
      userInfo: {},
      logoutVisible: false,
    };
  },
  created(){
    this.pushViewList();
  },
  mounted() {
    const token = Cookies.get('token')
    var msg = JSON.parse(sessionStorage.getItem("userInfo"));   
    if (msg == null || msg == "") {
      getUserInfo(token).then((res) => {
        this.userInfo=res
      });
    }else{
      this.userInfo=msg
    }
  },
  methods: {  
    pushViewList(){
      GetPushUserCheckList().then(res=>{
        if(res.ResultObject.length ==0){
          this.messageshow=true;
        }else{
          this.messageshow=false;
        }
      });
    },
    tomessagelist(){
      this.$router.push({
        path: "/message",
      });
    },
    proxyImage: function (e) {
      console.log(e.target)
      if (e.target.id === 'left') {
          // 这里是需要执行的方法
          window.open("https://www.jinkunzibo.com","_blank");
      }
    },
    handleCommand(command) {
      if (command == "password") {
        if (this.$route.path == "/pass") {
          this.$message.warning("当前已在修改密码页！请勿重复点击");
        } else {
          this.$router.push({
            path: "/pass",
            query: {
              name: this.userInfo.Name,
            },
          });
        }
      } else if (command == "logout") {
        this.logoutVisible = true;   
      }
    },
    logoutBtn() {
      //清除cookie值
      Cookies.remove("token");
      //清除userInfo
      sessionStorage.removeItem("userInfo");
      //清除路由缓存
      sessionStorage.removeItem("rewriteRoutes");
      //清除菜单栏缓存
      sessionStorage.removeItem("sidebarRoutes");

      this.logoutVisible = false;
      this.$router.push("/login");
      this.$message.success("退出登录成功！");
    },
  },
};
</script>

<style lang="scss" scoped>
.head-nav {
  margin: 0;
  width: 100%;
  height: 80px;
  background: url(../../assets/images/head.png) no-repeat center;
  background-size: 110% 80px;
}
.head-container {
  width: 1200px;
  margin: 0 auto;
}
.logo-container {
  float: left;
  margin: 10px 0 0 20px;
}
.setting-container {
  float: right;
  margin-top: 22px;
}
.msg-btn,
.user-info,
.user-head,
.user-name,
.setting-dropdown {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  width: auto !important;
  .userinfobox{
    .usernamebox{
      padding-right: 30px;
    }
  }
}
.msg-btn {
  padding: 0 50px;
  font-size: 24px;
  cursor: pointer;
}
::v-deep .el-badge__content.is-fixed.is-dot {
  right: 10px;
}
::v-deep .el-badge__content.is-fixed {
  top: 7px;
}
.user-head {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  border-radius: 18px;
  background-color: #fff;
  // img {

  // }
}
// .user-name {
//   margin-right: 6px;
//   cursor: pointer;
// }
.setting-dropdown {
  position: relative;
  width: 50px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  div {
    i {
      position: absolute;
      top: 9px;
      right: 0;
      // margin: 5px 0 0 6px;
      font-size: 20px;
      line-height: 16px;
    }
  }
}
.logout {
  &:hover {
    color: #f56c6c;
    background-color: #ffffff;
    // background-color: #fef0f0;
    // text-decoration: underline;
  } /* 鼠标移动到链接上 */
}
</style>