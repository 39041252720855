<template>
  <div>
    <template v-if="!item.children">
      <el-menu-item :index="item.path" :title="item.meta.title ">
        <i :class="item.meta.icon ? item.meta.icon : 'el-icon-user-solid'"></i>
        <span slot="title">{{ item.meta.title }}</span>
      </el-menu-item>
    </template>

    <el-submenu v-else :index="item.path">
      <template slot="title">
          <i :class="item.meta.icon ? item.meta.icon : 'el-icon-user-solid'"></i>
          <span slot="title">{{ item.meta.title }}</span>
        
      </template>
      <el-menu-item v-for="subItem in item.children" :index="subItem.path" :key="subItem.path">
        {{ subItem.name }}
      </el-menu-item>
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: "asidemenu-item",

  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .el-menu-item:hover{
	  background: #eeeff0!important;
	}
	
</style>