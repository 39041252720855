<template>
  <div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      router
      :collapse="isCollapse"
      :background-color="variables.menuBg"
      :text-color="variables.menuText"
      :active-text-color="variables.menuActiveText"
      collapse-transition
    >
      <!-- <el-menu-item
        v-for="item in menuList"
        :index="item.path ? item.path : item.id"
      >
        <i :class="item.icon ? item.icon : 'el-icon-user-solid'"></i>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item> -->
      <AsidemenuItem
        v-for="route in menuList"
        :key="route.path"
        :item="route"
      />
      <!-- <AsidemenuItem v-for="route in menuList" :item="route" /> -->
    </el-menu>
  </div>
</template>

<script>
import variables from "@/assets/styles/variables.module.scss";
import AsidemenuItem from "./AsidemenuItem.vue";
import { setMenu } from "../../../utils/datautils/tempRequstData";
import { loadMenus2,loadMenus } from "../../../router/index" ;
import Cookies from 'js-cookie';
import { getUserInfo } from '../../../api/apis'
export default {
  components: {
    AsidemenuItem,
  },
  data() {
    return {
      //是否折叠
      isCollapse: false,
      menuList: [
        {
          name: "首页",
          //路由地址
          path: "/home",
          meta: {
            title: "首页",
            icon: "el-icon-s-home",
          },
        },

      ],
    };
  },
  mounted() {
    const token = Cookies.get('token')
       getUserInfo(token).then(res=>{
         //将用户信息转成字符串
        var userInfo = JSON.stringify(res);
        sessionStorage.setItem("userInfo", userInfo);
        loadMenus(setMenu(res))
        var storemenu = this.$store.state.routers.asideMenuRouters
        if(storemenu.length==0){
          this.menuList = this.menuList.concat(JSON.parse(sessionStorage.getItem("sidebarRoutes"))) 
        }else{
          this.menuList = this.menuList.concat(this.$store.state.routers.asideMenuRouters)
        }
    })
  },
  computed: {
    variables() {
      return variables;
    },
    routes() {
      return this.$store.state.routers.routers;
    },
  },
  methods: {
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 170px;
  min-height: 400px;
}
::v-deep .el-submenu .el-menu-item {
  width: 170px;
  min-width: 170px;
}
.el-menu {
  border-right: 0px;
  // background-color: transparent;
}
.el-dropdown-menu__item,
.el-menu-item {
  font-size: 16px;
}
.el-menu-item [class^="el-icon-"] {
  font-size: 20px;
}

</style>