import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../layout/index'
import HomeView from '../views/home/HomeView.vue'
import LoginView from '../views/LoginView'
//constant常量
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
//constant常量
export let constantRouterMap = [
  {
    path: '/login',
    meta: { title: '登录' }, 
    name:'login',
    component: LoginView,
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/features/404NotFound.vue'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'home',
    meta: {
      title: '首页',
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView,
        meta: {
          title: '首页',
        }
      },
      {
        path: 'pass',
        name: 'pass',
        component: () => import('@/views/home/components/ChangePassword.vue'),
        meta: {
          title: '修改密码',
        }
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import('@/views/feedback/feedback.vue'),
        meta: {
          title: '问题反馈',
        }
      },
      {
        path: 'message',
        name: 'message',
        component: () => import('@/views/message/message.vue'),
        meta: {
          title: '消息提示',
        }
      },
    ]
  },
]



export default new Router({
  mode: 'history',
// scrollBehavior当切换到新路由时，想要页面滚到顶部，或者是保持原先的滚动位置
  scrollBehavior: () => ({ y: 0 }),
  //自带的，不知道是啥
  base: process.env.BASE_URL,
  routes: constantRouterMap,
  //routes: [...constantRouterMap,...(cacheRoutesArr||[])]
})

