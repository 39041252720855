import request from '@/utils/request'

//登录
export function Userlogin(acc,pwd){
  return request.get('/Default/Login/?username='+acc+'&password='+pwd)
};
//验证码登录
export const CheckPhoneLogin = params =>{
  return request.post('/Default/CheckPhoneLogin',params)
};
//获取验证码
export function GetVerificationCode2(phone){
  return request.post('/OrgCommon/VerificationCode_BankCard?phone='+phone)
};
//获取验证码
export const GetVerificationCode = params =>{
  return request.post('/OrgCommon/VerificationCode_MemberLogin',params)
};
//获取图形验证码
export function GetImgCode(phone){
  return request.get('/Default/GetCode?p='+phone)
};
//首页基础信息
export function HomeGetInfo(){
  return request.get(`/Home/GetInfo`)
};
//首页指标信息
export const GetTargetList = params => {
  return request.post('/Home/GetTargetList',params);
};
//首页指标趋势图信息
export const GetTargetTrendInfo = params => {
  return request.post('/Home/GetTargetTrendInfo',params);
};
//根据用户id查询用户
export const GetOrgUser= params => {
  return request.post(`/Users/GetInfo`, params);
};
export const getUserInfo = (params) => {
  return request.post('/Default/GetUserInfo');
};
//记录推介官登录
export const LiverLoginLog = (params) => {
  return request.post('/Default/LiverLoginLog');
};
export const logout = params => {
  return request.get(`/ComputeLog/GetAssetLog`, {params: params});
};
//司辅资产列表
export const GetAssetList = params => {
  return request.post(`/OrgAsset/Query`, params);
};
//资产复制
export const GetAssetCopy = params => {
  return request.post(`/OrgAsset/AssetCopy`, params);
};
//资产二维码
export function GetAssetShare(code) {
  return request.get(`/OrgAsset/AssetShare?v=`+code);
};
//所有资产列表
export const GetAllQueryList = params => {
  return request.post(`/OrgAsset/AllQuery`, params);
};
// 根据资产名称、处置价、拍卖流程判断资产是否存在
export const GetByAssettMode = params => {
  return request.post(`/OrgAsset/GetByAssettMode`, params);
};
//司辅资产新增
export const GetAssetAdd = params => {
  return request.post(`/OrgAsset/Add`, params);
};
//城市三级联动
export function GetDivision(code) {
  return request.get(`/OrgCommon/DivisionFormSelect?regionCode=`+code);
};
//司辅资产编辑
export const GetAssetUpdate = params=> {
  return request.post(`/OrgAsset/Update`,params);
};
//修改成交价
export const UpdateTPrice = params=> {
  return request.post(`/OrgAsset/UpdateTPrice`,params);
};
//司辅资产下架
export function GetOffLine(id){
  return request.post(`/OrgAsset/OffLine?ids=`+id);
};
//司辅资产详细
export function GetAssetInfo(id){
  return request.get(`/OrgAsset/GetInfo?id=`+id);
};
//司辅资产删除
export function GetAssetDel(id){
  return request.post(`/OrgAsset/Deletes?id=`+id);
};
//资产文件删除
export function GetDeleteFiles(id){
  return request.post(`/OrgCommon/DeleteFiles?id=`+id);
};
//资产图片设置为首图
export function SetFirstImg(assetId,fid){
  return request.get(`/OrgCommon/FirstImg?assetId=`+assetId+'&fid='+fid);
};
//获取文件列表
export const GetFileList= params =>{
  return request.post(`/OrgCommon/FilesList`,params)
};
//获取文件列表
export function QueryByGuidFile(guid){
  return request.get(`/OrgCommon/QueryByGuidFile?guid=`+guid)
};
//根据机构设置补充文件列表
export const GetOrgFileList= params =>{
  return request.post(`/OrgCommon/OrgFilesList`,params)
};
//添加资产视频文件列表
export const AddTxVideoFile= params =>{
  return request.post(`/OrgCommon/AddTxVideoFile`,params)
};
//获取审核记录信息
export function GetSubmitLog(id,type){
  return request.post(`/OrgCommon/SubmitLog?assetid=`+id+`&type=`+type)
};
//资产审核
export const AssetSubmit= params =>{
  return request.post(`/OrgAsset/Submit`,params)
};
//获取审核状态下拉列表
export function AssetState(){
  return request.get(`/OrgAsset/AssetState`)
};
/// 获取处置平台下拉列表
export function DisposalPlatform(){
  return request.get(`/OrgAsset/DisposalPlatform`)
};
//获取拍卖流程下拉列表 
export function FlowType(){
  return request.get(`/OrgAsset/FlowType`)
};
//获取处置状态下拉列表
export function AssetDisposalState(){
  return request.get(`/OrgAsset/AssetDisposalState`)
};
//获取机构列表
export function PartnersList(){
  return request.get(`/OrgAsset/PartnersList`)
};
//获取资产来源下拉列表 
export function AssetSource(){
  return request.get(`/OrgAsset/AssetSource`)
};
//获取资产类型下拉列表
export function AssetType(){
  return request.get(`/OrgAsset/AssetType`)
};
//获取推介状态下拉列表
export function LiveState(){
  return request.get(`/OrgAsset/LiveState`)
};
//获取处置模式下拉列表
export function DisposalMode(){
  return request.get(`/OrgAsset/DisposalMode`)
};
//获取处置程序下拉列表
export function DisposalProgram(){
  return request.get(`/OrgAsset/DisposalProgram`)
};
//获取省级列表
export function ProvinceChangeList(){
  return request.get(`/OrgCommon/ProvinceChangeList`)
};
//根据编码获取城市列表
export function CityChangeList(code){
  return request.get(`/OrgCommon/CityChangeList?parentCode=`+code)
};
//根据来源id查询资源类型
export function QueryResourceType(parentId){
  return request.get(`/OrgCommon/QueryResourceType?parentId=`+parentId)
};
//获取视频类型下拉列表
export function GetVideoType(){
  return request.get(`/OrgVideo/VideoType`)
};
//司辅视频列表
export const GetVideoList = params => {
  return request.post(`/OrgVideo/Query`, params);
};
//获取云视频上传密匙
export function GetSignature(){
  return request.get(`/OrgVideo/Signature`)
};
//添加司辅视频
export const AddTxVideo = params => {
  return request.post(`/OrgVideo/AddTxVideo`, params);
};
//编辑司辅视频
export const GetUpdteVideo= params =>{
  return request.post(`/OrgVideo/UpdateTxVideo`,params);
};
//获取司辅视频详细信息
export function GetVideoInfo(id){
  return request.get(`/OrgVideo/GetVideoInfo?id=`+id)
};
//删除司辅视频
export function GetDeleteVideo(id){
  return request.post(`/OrgVideo/Deletes?id=`+id);
};
//视频宣传列表
export const OrgShareGetList = params => {
  return request.post(`/ShareVideo/GetShareVideoList`, params);
};
//视频宣传辅助列表(围观打趣....)
export const GetAssetBusinessInfo = params => {
  return request.post(`/ShareVideo/GetAssetBusinessInfo`, params);
};
//宣传记录列表
export const LogQueryList = params => {
  return request.post(`/OrgVideoLog/LogQuery`, params);
};
//添加宣传记录
export const AddVideoLog = params => {
  return request.post(`/OrgVideoLog/AddTxVideo`, params);
};
//添加宣传记录
export function GetOrgAssetBusiness(aid,phaseType){
  return request.get(`/OrgVideoLog/GetOrgAssetBusiness?aid=`+aid+'&phaseType='+phaseType);
};
//删除宣传记录
export function DelVideoLog(id){
  return request.post(`/OrgVideoLog/DeleteVideoLog?id=`+id);
};
//宣传记录详细
export function VideoLogDetail(id){
  return request.get(`/OrgVideoLog/GetVideoLogInfo?id=`+id);
};
//编辑宣传记录列表
export const UpdateVideoLog = params => {
  return request.post(`/OrgVideoLog/UpdateVideoLog`, params);
};
//业务共享
//共享资产列表
export const getAssetShareList = params => {
  return request.post(`/Share/GetAssetList`, params);
};
//共享资产列表
export const getAssetShareModel = params => {
  return request.post(`/Share/GetAssetInfo`, params);
};

//共享佣金规则
export const getShareModel = params => {
  return request.post(`/Share/GetAssetBusinessInfo`, params);
};
//查询数量
export const getShareNum = params => {
  return request.post(`/Share/GetShareNum`, params);
};
//查询视频宣传数量
export const GetVideoShareNum = params => {
  return request.post(`/ShareVideo/GetVideoShareNum`, params);
};
//共享记录列表
export const getAssetReportList = params => {
  return request.post(`/Share/GetReportList`, params);
};
//我的业务报备列表
export const getMyReportList = params => {
  return request.post(`/Share/GetMyReportList`, params);
};
//添加共享记录列表
export const AddAssetReport = params => {
  return request.post(`/Share/AddReport`, params);
};
//修改报备状态为审核中
export const UpdateReportAudit = params => {
  return request.post(`/Share/UpdateReportAudit`, params);
};
//修改报备状态
export const UpdateReportState = params => {
  return request.post(`/Share/UpdateReportState`, params);
};
//删除
export const delAssetReport = params => {
  return request.post(`/Share/DeleteReport`, params);
}

//报告报备
//对象
export const getWordsShareModel = params => {
  return request.post(`/Words/GetModel`, params);
}
//列表
export const getWordsShareList = params => {
  return request.post(`/Words/GetList`, params);
}
//列表数量
export const getWordsStatusNum = params => {
  return request.post(`/Words/GetStateNum`, params);
}
//添加
export const addWordAgency = params => {
  return request.post(`/Words/AddReport`, params);
}
//删除
export const delWordAgency = params => {
  return request.post(`/Words/Delete`, params);
}
//获取锦鲲客流列表
export const GetcustomerList = params=>{
  return request.post(`/Demand/GetList`, params);
}
//获取寻求资源列表
export const GetSeekList = params=>{
  return request.post(`/Demand/GetSeekList`, params);
}
//获取寻求资源列表
export const GetSeekListCount = params=>{
  return request.post(`/Demand/GetSeekListCount`, params);
}
//锦鲲客流新增客户
export const GetcustomerAdd = params=>{
  return request.post(`/Demand/Add`, params);
}
//添加寻求资源
export const GetcustomerAddResource = params=>{
  return request.post(`/Demand/AddResource`, params);
}
//锦鲲客流编辑客户
export const GetcustomerUpdate = params=>{
  return request.post(`/Demand/Update`, params);
}
//删除寻求资源
export const DelSeekInfo = params=>{
  return request.post(`/Demand/Deletes`, params);
}
//回款统计
//视频宣传记录回款
export const GetVideoOrderInfo = params => {
  return request.post(`/PriceOrder/GetVideoOrderInfo`, params);
};
//回款统计详情
export const getPriceOrderInfo = params=>{
  return request.post(`/PriceOrder/GetInfo`, params);
}
//回款统计列表
export const getPriceOrderList = params=>{
  return request.post(`/PriceOrder/GetList`, params);
}
//回款统计编辑
export const PriceOrderUpdate = params=>{
  return request.post(`/PriceOrder/Update`, params);
}
//数量统计
export const getPriceOrderNum = params=>{
  return request.post(`/PriceOrder/GetStateNum`, params);
}
//金额统计
export const getOrderPriceCount = params=>{
  return request.post(`/PriceOrder/GetPriceNum`, params);
}
//收益详细页
//收益详细信息
export const getOrderPriceInfo = params=>{
  return request.post(`/PriceOrder/GetOrderInfo`, params);
}
//成员管理获取列表
export const GetUsersList = params=>{
  return request.post(`/Users/GetList`, params);
}
//成员管理创建人员
export const GetUsersAdd = params=>{
  return request.post(`/Users/Add`, params);
}
//成员管理人员删除
export const GetUsersDeletes = params=>{
  return request.post(`/Users/Deletes`, params);
}
//修改密码
export const GetUpdatePwd = params=>{
  return request.post(`/Default/UpdatePwd`, params);
}
//获取企业信息
export const GetPartnersInfo = params=>{
  return request.post(`/Users/GetPartnersInfo`, params);
}
//获取企业认证图片
export function GetPartnersImg(v){
  return request.post(`/Users/GetPartnersImg?v=`+v);
}
//修改企业信息
export const UpdatePartners = params=>{
  return request.post(`/Users/UpdatePartners`, params);
}
//企业打款信息
export const GetDealAccountInfo = params=>{
  return request.post(`/Users/GetDealAccountInfo`, params);
}
//修改企业打款信息
export const UpdateDealAccount = params=>{
  return request.post(`/Users/UpdateDealAccount`, params);
}
//修改企业头像
export const UpdatePartnerHeadImg = params=>{
  return request.post(`/Users/UpdatePartnerHeadImg`, params);
}
//获取播手个人信息
export const GetLivePersonsInfo = params=>{
  return request.post(`/Users/GetLivePersonsInfo`, params);
}
//修改播手个人信息
export const UpdateLivePersons = params=>{
  return request.post(`/Users/UpdateLivePersons`, params);
}
//修改播手个人头像
export const UpdateLivePersonsHeadImg = params=>{
  return request.post(`/Users/UpdateLivePersonsHeadImg`, params);
}

//我的视频宣传报备列表
export const MyLogQuery = params=>{
  return request.post(`/OrgVideoLog/MyLogQuery`, params);
}
//我的视频宣传报备列表
export function OrgPubliccityInfo(id){
  return request.get(`/OrgCommon/OrgPubliccityInfo?id=`+id);
}
//消息列表
export const GetPushViewList = params=>{
  return request.post(`/PushNotification/GetPushViewList`, params);
}
//消息是否点击
export const GetPushUserCheckList = params=>{
  return request.post(`/PushNotification/GetPushUserCheckList`, params);
}
//改变推送用户表的是否查看 
export function UpdateUnCheck(uid){
  return request.post(`/PushNotification/UpdateUnCheck?uid=`+uid);
}
//获取播手个人信息
export const AddProblem = params=>{
  return request.post(`/OrgCommon/AddProblem`, params);
}

//下载资产分享图 
export const GetAssetShareImg = params => {
  return request.get(`/OrgAsset/GetAssetShareImg`, {params: params});
};
//微信公众号二维码
export function GetWxGzhQRcode(){
  return request.get(`/OrgCommon/GetWxGzhQRcode`)
};
//获取寻求资源列表信息 
export const QuestResourceList = params =>{
  return request.post(`/QuestResource/GetList`, params);
}
//添加寻求资源信息
export const AddQuestResource = params =>{
  return request.post(`/QuestResource/Add`, params);
}
//删除寻求资源信息 
export const DelQuestResource = params =>{
  return request.post(`/QuestResource/Deletes`,params);
}
//GetShareNum
export const GetQuestResourceNum = params =>{
  return request.post(`/QuestResource/GetQuestResourceNum`,params);
}
//下载资产分享图 
export const GetVideoShareImg = params => {
  return request.get(`/OrgVideo/GetShareImg`, {params: params});
};