import { constantRouterMap } from '@/router/routers'
import Layout from '@/layout/index'
import LayoutSub from '@/layout/sub'

const routers = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    asideMenuRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    SET_ASIDEMENU_ROUTERS: (state, routers) => {
      state.asideMenuRouters = routers
    }
  },
  actions: {
    GenerateRoutes({ commit }, asyncRouter) {
      commit('SET_ROUTERS', asyncRouter)
    },
    SetAsideMenuRouters({ commit }, sidebarRouter) {
      commit('SET_ASIDEMENU_ROUTERS', sidebarRouter)
    }
  }
}

//主要目的：处理组件，用于注册路由
//菜单栏不使用这个数据
export const filterAsyncRouter = (routers, type = 1) => {
  // 遍历后台传来的路由字符串，转换为组件对象
  // 路由注册
  return routers.filter(router => {
    if (type == 2 && router.children) {
      router = mapAsideMenuRouter(router)
    }

    if (router.component) {
      if (router.component == 'Layout') { // Layout组件特殊处理
        router.component = Layout
      } else if (router.component == 'LayoutSub') {
        router.component = LayoutSub
      } else {
        const component = router.component
        router.component = loadView(component)
      }
    }
    if (router.children != null && router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children)
    } else {
      delete router['children']
      delete router['redirect']
    }
    return true
  })

}
function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'LayoutSub') {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}
function mapAsideMenuRouter(router) {
  for (let i = 0; i < router.children.length; i++) {
    if (router.children[i].component !== "LayoutSub") {
      
      router.path = router.path + '/index'
      // 将最后一级的children删掉，因为是功能页面，不需要加载到菜单
      delete router['children']
      break;
    } else {
      // 如果有下一级
      router.children[i].path = router.path + '/' + router.children[i].path

      if (router.children[i].children != null && router.children[i].children && router.children[i].children.length) {

        mapAsideMenuRouter(router.children[i])
      }
    }
  }
  return router
  // return routers.map(item,index) ={

  // }
}

export const loadView = (view) => {
  // import不支持变量？
  return (resolve) => require([`@/views/${view}`], resolve)
  // return () => import(`@/views/${view}`)
}

export default routers