<template>
  <div
    :class="{'hidden':hidden}"
    class="pagination-container"
  >
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :page-sizes="pageSizes"
      :layout="layout"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>

export default {
  name: 'pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 50]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        //当currentPage发生改变时调用
        //双向绑定(将数据传递给父组件，父组件使用.sync获取后再传回子组件)
        //父组件只在第一次传值进来，之后子组件修改会返回给父组件进行数据查询
        //直接使用首先改变值会报错，其次并不好传值
        // 我试了一下使用watch,watch用prop的数据好像有点问题
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    //pageSize（每页显示个数）改变时会触发
    handleSizeChange(val) {
      //val代表每页多少条
      this.$emit('pagination', { page: this.currentPage, limit: val })
    },
    //currentPage(当前页数)改变时会触发
    handleCurrentChange(val) {
      //val代表当前多少页
      this.$emit('handleChange',val)
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
}
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 32px 16px;
  text-align: center;
}
.pagination-container.hidden {
  display: none;
}

/* ::v-deep .el-pager li {
  background-color: transparent;
} */
/* 按钮右 */
::v-deep .el-pagination.is-background .btn-next,
/* 按钮左 */
::v-deep .el-pagination.is-background .btn-prev,
/* 页码未选中选状态 */
::v-deep .el-pagination.is-background .el-pager li {
  background-color: transparent;

}
</style>
