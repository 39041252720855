<template>
  <div class="Presentationbox">
    <div class="miambox">
      <div class="miambox_left">
        <div class="left_frist">JK.LINK</div>
        <div class="left_two">
          <div>锦鲲智慧生态</div>
          <div>共享平台</div>
        </div>
        <div class="left_three">
          <div>打造特殊资产行业命运共同体，高效推动资产绿色去化。</div>
          <div>我们一起在行动！</div>
        </div>
      </div>
      <div class="miambox_right">
        <div class="right_one">欢迎您的登录</div>
        <div class="right_two">又是美好的一天，快让我们开启智慧之旅吧！</div>
        <div class="right_three">
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="loginFormRules"
            autocomplete="on"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="ruleForm.phone"
                placeholder="请输入手机号"
                prefix-icon="el-icon-user"
                autocomplete="on"
                name="username"
                size="medium"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="ruleForm.imgcode"
                placeholder="请输入图形验证码"
                autocomplete="off"
                size="medium" style="width: 150px;"
              ></el-input> 
           <el-image v-if="imgcodesta" :src="imgcode"  
                style="width:88px;height:40px;overflow: initial;margin-left:10px" ></el-image>
              <el-button @click="getImgCode()" style="width:100px; padding:12px 14px;margin-left:10px">获取验证码</el-button>
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                v-model="ruleForm.code"
                placeholder="请输入短信验证码"
                autocomplete="off"
                size="medium" style="width: 210px;margin-right: 10px;"
              ></el-input>
              <el-button @click="getVerification()">{{text}}</el-button>
            </el-form-item>

            <el-form-item>
              <button
                @keyup.enter="keyDown(e)"
                @click="loginBtn('ruleForm')"
                type="button"
                class="loginbt"
              >
                登录
              </button>
            </el-form-item>
          </el-form>
        </div>
        <!-- <div class="right_four">忘记密码？</div> -->
      </div>
    </div>
    <div class="filingsbox">
      <img src="../assets/images/emblem.png" />
      <div class="textstyle">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          蜀ICP备2021012263号
        </a>
        © CopyRight 2021-2023, jinkunzibo.com, Inc.All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import { Userlogin, getUserInfo,GetVerificationCode,CheckPhoneLogin,LiverLoginLog,GetImgCode} from "../api/apis";
import { setMenu } from "../utils/datautils/tempRequstData";
import { loadMenus2 } from "../router/index" 

export default {
  data() {
    // 表单
    return {
      min:60,
      text:"获取短信验证码",
      ruleForm: {
        phone: "",
        code: "",
        imgcode:""
      },
      // 复选框
      remUserName: false,
      remPass: false,
      // 表单的验证规则
      loginFormRules: {
        // required是否必须，message提示信息，type：数据类型，blur失去焦点
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      imgcode:'',
      imgcodesta:false,
    };
  },
  mounted(){
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed(){
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    // 点击回车键登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode == 13) {
        this.loginBtn('ruleForm'); 
      }
    },
    //获取图片验证码
    getImgCode(){ 
      if(!(/^1[3456789]\d{9}$/.test(this.ruleForm.phone))){  
        this.$message.error('请填写正确的手机号！');
        return false; 
      } 
      GetImgCode(this.ruleForm.phone).then(res=>{
            if(res.IsSuccess){
              this.imgcodesta = true; 
              this.imgcode ='data:image/jpg;base64,'+ res.Msg;
            }
          });
    },
    //获取验证码
    getVerification(){
      let _this=this;     
      if(this.text=="获取短信验证码"){
        if(_this.ruleForm.phone !=""){
          if(_this.ruleForm.imgcode==""){
            this.$message.error("请输入图形验证码");
            return;
          }
          GetVerificationCode({Phone:_this.ruleForm.phone,ImgCode:_this.ruleForm.imgcode}).then(res=>{
            if(res.IsSuccess){
              this.text="重新发送("+this.min+"s)";
              for (var i = 0; i < this.min; i++) {
                (function (j) { 
                  setTimeout(function() { 
                    _this.min=_this.min-1;
                    if(_this.min==0){
                      _this.text="获取验证码";
                      _this.min=60;
                    }else{
                      _this.text="重新发送("+_this.min+"s)";
                    }
                  }, 1000 * j); 
                })(i); 
              }          
            }else{
              this.$message.error(res.Msg);
            }
          });
        }else{
          this.$message.error("请先输入手机号");
        }     
      }
    },
    //登录按钮
    loginBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return this.$message.error("登陆失败，请输入手机号及验证码");
        } else {
          //调用接口
            CheckPhoneLogin(this.ruleForm).then((res) => {
              if (res.IsSuccess) {
                //token信息存入cookie
                Cookie.set("token", res.Msg,{expires:68});
                getUserInfo(res.Msg).then((res) => {                 
                  //将用户信息转成字符串
                  var userInfo = JSON.stringify(res);
                  sessionStorage.setItem("userInfo", userInfo);
                  //推介官登录记录信息
                  if(res.Type==6 || res.Type==7){
                    LiverLoginLog().then(nres=>{
                    });
                  }
                  //改变菜单
                  loadMenus2(setMenu(res))
                  //跳转首页
                  this.$message({
                    message: "登陆成功",
                    type: "success",
                  });
                });
              } else {
                this.$message.error(res.Msg);
              }
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 48px;
}
.Presentationbox {
  width: 100%;
  height: 100%;
  background-color: #f2f6ff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .miambox {
    display: flex;
    box-shadow: 0px 2px 6px #d9edff;
    border-radius: 40px;
    .miambox_left {
      width: 600px;
      height: 700px;
      border-radius: 40px 0 0 40px;
      background: url("@/assets/images/loginbg.png") no-repeat;
      overflow: hidden;
      padding: 156px 0 0 96px;
      box-sizing: border-box;
      color: #ffffff;
      .left_frist {
        font-size: 72px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .left_two {
        font-size: 48px;
        margin-bottom: 20px;
      }
      .left_three {
        font-size: 16px;
        div {
          margin-bottom: 12px;
        }
      }
    }
    .miambox_right {
      width: 600px;
      height: 700px;
      border-radius: 0 40px 40px 0;
      background-color: #ffffff;
      overflow: hidden;
      padding: 120px 120px 0 120px;
      box-sizing: border-box;
      .right_one {
        font-size: 28px;
        color: #333333;
        font-weight: 700;
        margin-bottom: 18px;
      }
      .right_two {
        font-size: 16px;
        color: #b2b2b2;
        margin-bottom: 50px;
      }
      .right_three {
        .loginbt {
          width: 100%;
          height: 48px;
          background-color: #3077ff;
          border: none;
          border-radius: 4px;
          color: #ffffff;
          font-size: 16px;
          font-weight: 600;
          margin-top: 30px;
          cursor: pointer;
        }
      }
      .right_four {
        width: 100%;
        display: flex;
        justify-content: center;
        color: #666666;
        font-size: 14px;
        margin-top: 40px;
        cursor: pointer;
      }
      .right_four:hover {
        color: #3077ff;
        text-decoration: underline;
      }
    }
  }
  .filingsbox {
    position: fixed;
    bottom: 30px;
    width: 100%;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    .textstyle {
      margin-left: 6px;
      a {
        color: #999999;
      }
      a:hover {
        color: #3077ff;
      }
    }
  }
}
</style>