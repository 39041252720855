<template>
  <!-- 首页折线图 -->
  <div>
    <div id="linechart1" style="height: 350px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.drawChart()
  },
  methods: {
    drawChart() {
      let lineChart = this.$echarts.init(
        document.getElementById("linechart1")
      );
      let option = {
        grid: {
          left: "10%",
          right: "5%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [10, 20, 901, 100, 1290, 1500, 500],
            type: "line",
            //曲线
            // smooth: true,
            //圆点
            symbol: 'circle',
            // symbol: 'none',
            symbolSize: 6,
            lineStyle: {
              // color: "#1890FF",
              color: "#1890FF",
              
            },
            //背景
            areaStyle: {
              color: "#E7F4FF"
            },
            itemStyle: {
              color: '#1890FF',
              borderColor: "#fff",
            }
          },
        ],
      };

      option && lineChart.setOption(option);
    },
  },
};
</script>

<style lang="sass" scoped>
</style>