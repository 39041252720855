<template>
  <div class="main-layout">
    <div class="layout-head">
      <HeadNav />
      <center>     
        <div class="rollbox" v-if="rollshow">
          <div class="closebox" @click="rollshow=false">
            <i class="el-icon-error"></i>
          </div>
          <marquee direction="left"> {{text}}</marquee>
        </div>
      </center>
    </div>
    <!--二维码浮动盒子-->
    <div class="floatbox" :class="{ tcnoshowstyle:tcshow == false}">
      <div class="QRcodebox">
        <div class="QRimg">
          <img :src="imgurl">
        </div>
      </div>
      <div class="tcclosebox"  >
        <i class="el-icon-error closeicon" @click="colestcbox"></i>
      </div>
    </div>
    <!-- 中心板块 -->
    <div class="container">
      <!-- 菜单栏 -->
      <div class="aside-menu">
        <AsideMenu />
      </div>
      <!-- 应用板块 -->
      <div class="main-container">
        <AppMain />
      </div>
    </div>
    <!-- 底部 -->
    <div class="layout-footer">
      <div class="footer-in">
        <div class="footer-content">
          <img src="../assets/images/emblem.png" alt="" />
          <span>蜀ICP备2021012263号 © CopyRight 2021-2022, jinkunzibo.com, Inc.AllRights Reserved.</span>
          <i class="el-icon-phone-outline" style="margin-left: 20px; margin-right: 5px;"></i>
          <span @click="dialogFormVisible=true" class="zhichi">技术支持</span>
          <span @click="tofeedback" class="fankui">问题反馈</span>
        </div>
      </div>
    </div>
    <!-- 技术支持信息 -->
    <el-dialog
      title="技术支持信息"
      :visible.sync="dialogFormVisible"
      width="220px"
      :close-on-click-modal="false"
    >
    <div>咨询电话1：18782221149</div>
    <div style=" margin-top: 10px;">咨询电话2：15150339401</div>
    <div style=" margin-top: 10px;">咨询时间：09:10-17:30</div>
    </el-dialog>
  </div>
</template>

<script>
import { AppMain, AsideMenu, HeadNav} from "./components";
import { OrgPubliccityInfo,GetWxGzhQRcode} from "../api/apis.js";
export default {
  name: "layout",
  components: {
    AppMain,
    AsideMenu,
    HeadNav,
  },
  created(){
    this.getData(1);
    this.getWxGzhQRcode();
  },
  data() {
    return {
      tcshow:true,
      text:"",
      imgurl:"",
      dialogFormVisible:false,
      rollshow:false
    };
  },
  methods: {
    //获取微信公众号二维码
    getWxGzhQRcode(){
      GetWxGzhQRcode().then(res=>{
        if(res !=null){
          this.imgurl='https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+res;
        }
      });
    },
    colestcbox(){
      if(this.tcshow==true){
        this.tcshow=false
      }else{
        this.tcshow=true
      }
    },
    tofeedback(){
      this.$router.push({
        path: "/feedback",
      });
    },
    getData(id){
      OrgPubliccityInfo(id).then(res=>{
        if(res.IsSuccess){
          this.text=res.ResultObject.Text;
          if(this.text !=null){
            this.rollshow=true;
          }
        }
        console.log(res);
      });     
    }
  }
};
</script>

<style lang="scss" scoped>
.rollbox{
  width: 1200px;
  background-color: rgb(245, 243, 243);
  color: #0691d1;
  padding: 0 50px;
  box-sizing: border-box;
  border-radius:13px;
  font-size: 16px;
  height: 26px;
  position: relative;
  .closebox{
    position: absolute;
    right: 13px;
    font-size: 20px;
    cursor: pointer;
    color: #ff6969;
  }
  .closebox:hover{
    color: #ff2323;
  }
}
.floatbox{
  position: fixed;
  right: 25px;
  top: 300px;
  z-index: 10000;
  width: 130px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .QRcodebox{
    width: 130px;
    height: 210px;
    background-image: url("../assets/images/QRcodebg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .QRimg{
      width:72px;
      height: 72px;
      position: absolute;
      bottom: 29px;
      left: 29px;
      img{
        width:72px;
        height: 72px;
      }
    }
  }
  .tcclosebox{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    .closeicon{
      font-size: 28px;
      cursor: pointer;
      color: #b8b8b8;
    }
    .closeicon:hover{
      color: #707070;
    }
  } 
}
.tcnoshowstyle{
  display: none;
}
.main-layout {
  height: 100%;
  min-width: 1200px;
}
.container {
  position: relative;
  width: 1200px;
  min-height: 100%;
  margin: 0 auto;
  padding-top: 40px;
  // background-color: rgb(255, 153, 0);
}
// .aside-menu,
// .main-container {
//   display: inline-block;
// }
.aside-menu {
  position: absolute;
  top: 40px;
  width: 170px;
  min-height: 600px;
}
.main-container {
  margin-left: 170px;
  padding-left: 30px;
  border-left: 1px solid #EBEEF5;
}
// 底部
.layout-footer {
  height: 60px;
  margin-top: 50px;
  background-color: #fff;
}
.footer-in {
  width: 1200px;
  padding-top: 20px;
  margin: 0 auto;
  .footer-content {
    margin-left: 120px;
    text-align: center;
    .fankui{
      margin-left: 20px;
    }
    .zhichi:hover{
      color: #ff2323;
      cursor: pointer;
    }
    .fankui:hover{
      color: #ff2323;
      cursor: pointer;
    }
  }
  img {
    vertical-align: middle;
    margin-right: 10px;
  }
  span {
    font-size: 14px;
    font-family: Segoe UI-Regular, Segoe UI;
    font-weight: 400;
    color: #707070;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
}
</style>