
//1.司辅机构-主账号
const routersData1 = [
  {
    alwaysShow: true,

    //组件
    component: 'Layout',
    name: "资产管理",
    //路由地址
    path: "/property",
    //重定向
    redirect: "noredirect",
    meta: {
      title: "资产管理",
      icon: "el-icon-s-finance",
      path: "property",
      keepAlive: false,

    },
    children: [
      {
        path: 'index',
        component: 'property/index',
        meta: {
          title: '资产列表',
        }
      },
      {
        path: 'add',
        component: 'property/PropertyAdd',
        meta: {
          title: '新增资产',
        },
        children: [
          {
            path: 'slep1',
            component: 'property/components/add/AddStep1',
            meta: {
              title: '新增资产',
            }
          },
          {
            path: 'slep2',
            component: 'property/components/add/AddStep2',
            meta: {
              title: '新增资产',
            }
          },
          {
            path: 'slep3',
            component: 'property/components/add/AddStep3',
            meta: {
              title: '新增资产',
            }
          },
        ]
      },
      
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "视频管理",
      icon: "el-icon-video-camera-solid",
      path: "video",
      keepAlive: false,

    },
    name: "视频管理",
    path: "/video",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'video/index',
        meta: {
          title: '视频列表',
        }
      },
      {
        path: 'add',
        component: 'video/VideoInfo',
        meta: {
          title: '新增视频',
        }
      },
      {
        path: 'edit',
        component: 'video/VideoInfo',
        meta: {
          title: '编辑视频',
        }
      },
    ]

  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "锦鲲客流",
      // icon: "icon-gongxiang iconfont",
      // icon: "iconfont icon-gongxiang",
      icon: "el-icon-s-custom",
      path: "customer",
      keepAlive: false,

    },
    name: "锦鲲客流",
    path: "/customer",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'customer/index',
        meta: {
          title: '客户列表',
        }
      },
      {
        path: 'add',
        component: 'customer/CustomerAdd',
        meta: {
          title: '新增客户',
        }
      },
      {
        path: 'followup',
        component: 'customer/CustomerAdd',
        meta: {
          title: '客户跟进',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "成员管理",
      icon: "el-icon-user-solid",
      path: "operator",
      keepAlive: false,

    },
    name: "成员管理",
    path: "/operator",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'operator/index',
        meta: {
          title: '成员列表',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "业务共享",
      icon: "el-icon-share",
      path: "transaction",
      keepAlive: false,
    },
    name: "业务共享",
    path: "/transaction",
    redirect: "noredirect",
    children: [
      {
        component: 'LayoutSub',
        name: '业务报备',
        path: 'share',
        meta: {
          keepAlive: false,
          path: "share",
          title: '业务报备'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/shareproperty/index",
            meta: {
              keepAlive: false,
              title: '资产列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/shareproperty/BusinessReportInfo",
            meta: {
              keepAlive: false,
              title: '业务报备'
            },
          },
          {
            path: 'MyReport',
            component: "transactionshare/shareproperty/MyReport",
            meta: {
              keepAlive: false,
              title: '我的报备'
            },
          }
        ]
      },
      {
        component: 'LayoutSub',
        name: '寻求资源',
        path: 'seek',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '寻求资源'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/seekresource/index",
            meta: {
              keepAlive: false,
              title: '寻求资源列表'
            },
          },
          {
            path: 'addseek',
            component: "transactionshare/seekresource/addseek",
            meta: {
              keepAlive: false,
              title: '新增寻求'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',
        name: '报告代理',
        path: 'agent',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '报告代理'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/reportagent/index",
            meta: {
              keepAlive: false,
              title: '报告代理列表'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',
        name: '回款统计',
        path: 'moenytotal',
        meta: {
          keepAlive: false,
          path: 'moenytotal',
          title: '回款统计'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/retmoneystatistics/index",
            meta: {
              keepAlive: false,
              title: '统计列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/retmoneystatistics/ReturnedMoneyInfo",
            meta: {
              keepAlive: false,
              title: '回款详情'
            },
          }
        ]
      }
    ],
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "企业信息",
      icon: "iconfont icon-qiye",
      // icon: "el-icon-s-tools",
      path: "webset",
      keepAlive: false,

    },
    name: "企业信息",
    path: "/webset",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'webset/index',
        meta: {
          title: '企业信息',
        }
      },
    ]
  }
]
//2.司辅机构-管理员
 const routersData2 = [
  {
    alwaysShow: true,

    //组件
    component: 'Layout',
    name: "资产管理",
    //路由地址
    path: "/property",
    //重定向
    redirect: "noredirect",
    meta: {
      title: "资产管理",
      icon: "el-icon-s-finance",
      path: "property",
      keepAlive: false,

    },
    children: [
      {
        path: 'index',
        component: 'property/index',
        meta: {
          title: '资产列表',
        }
      },
      {
        path: 'add',
        component: 'property/PropertyAdd',
        meta: {
          title: '新增资产',
        },
        children: [
          {
            path: 'slep1',
            component: 'property/components/add/AddStep1',
            meta: {
              title: '新增资产',
            }
          },
          {
            path: 'slep2',
            component: 'property/components/add/AddStep2',
            meta: {
              title: '新增资产',
            }
          },
          {
            path: 'slep3',
            component: 'property/components/add/AddStep3',
            meta: {
              title: '新增资产',
            }
          },
        ]
      },
      
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "视频管理",
      icon: "el-icon-video-camera-solid",
      path: "video",
      keepAlive: false,

    },
    name: "视频管理",
    path: "/video",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'video/index',
        meta: {
          title: '视频列表',
        }
      },
      {
        path: 'add',
        component: 'video/VideoInfo',
        meta: {
          title: '新增视频',
        }
      },
      {
        path: 'edit',
        component: 'video/VideoInfo',
        meta: {
          title: '编辑视频',
        }
      },
    ]

  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "锦鲲客流",
      // icon: "icon-gongxiang iconfont",
      // icon: "iconfont icon-gongxiang",
      icon: "el-icon-s-custom",
      path: "customer",
      keepAlive: false,

    },
    name: "锦鲲客流",
    path: "/customer",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'customer/index',
        meta: {
          title: '客户列表',
        }
      },
      {
        path: 'add',
        component: 'customer/CustomerAdd',
        meta: {
          title: '新增客户',
        }
      },
      {
        path: 'followup',
        component: 'customer/CustomerAdd',
        meta: {
          title: '客户跟进',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "成员管理",
      icon: "el-icon-user-solid",
      path: "operator",
      keepAlive: false,

    },
    name: "成员管理",
    path: "/operator",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'operator/index',
        meta: {
          title: '成员列表',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "业务共享",
      icon: "el-icon-share",
      path: "transaction",
      keepAlive: false,
    },
    name: "业务共享",
    path: "/transaction",
    redirect: "noredirect",
    children: [
      {
        component: 'LayoutSub',
        name: '业务报备',
        path: 'share',
        meta: {
          keepAlive: false,
          path: "share",
          title: '业务报备'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/shareproperty/index",
            meta: {
              keepAlive: false,
              title: '资产列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/shareproperty/BusinessReportInfo",
            meta: {
              keepAlive: false,
              title: '业务报备'
            },
          },
          {
            path: 'MyReport',
            component: "transactionshare/shareproperty/MyReport",
            meta: {
              keepAlive: false,
              title: '我的报备'
            },
          }
        ]
      },
      // {
      //   component: 'LayoutSub',
      //   name: '视频宣传',
      //   path: 'promotion',
      //   meta: {
      //     keepAlive: false,
      //     path: "promotion",
      //     title: '视频宣传'
      //   },
      //   children: [
      //     {
      //       // promotion
      //       path: 'index',
      //       component: "transactionshare/videopromotion/index",
      //       meta: {
      //         keepAlive: false,
      //         title: '视频宣传列表'
      //       },
      //     },
      //     {
      //       path: 'details',
      //       component: "transactionshare/videopromotion/VideoInfo",
      //       meta: {
      //         keepAlive: false,
      //         title: '视频宣传详情'
      //       },
      //     },
      //     {
      //       path: 'add',
      //       component: "transactionshare/videopromotion/PromotionInfo",
      //       meta: {
      //         keepAlive: false,
      //         title: '新增视频宣传'
      //       },
      //     },
      //     // {
      //     //   path: 'edit',
      //     //   component: "transactionshare/videopromotion/PromotionInfo",
      //     //   meta: {
      //     //     keepAlive: false,
      //     //     title: '编辑视频宣传'
      //     //   },
      //     // },
      //   ]
      // },
      {
        component: 'LayoutSub',
        name: '寻求资源',
        path: 'seek',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '寻求资源'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/seekresource/index",
            meta: {
              keepAlive: false,
              title: '寻求资源列表'
            },
          },
          {
            path: 'addseek',
            component: "transactionshare/seekresource/addseek",
            meta: {
              keepAlive: false,
              title: '新增寻求'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',
        name: '报告代理',
        path: 'agent',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '报告代理'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/reportagent/index",
            meta: {
              keepAlive: false,
              title: '报告代理列表'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',


        name: '回款统计',
        path: 'moenytotal',
        meta: {
          keepAlive: false,
          path: 'moenytotal',
          title: '回款统计'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/retmoneystatistics/index",
            meta: {
              keepAlive: false,
              title: '统计列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/retmoneystatistics/ReturnedMoneyInfo",
            meta: {
              keepAlive: false,
              title: '回款详情'
            },
          }
        ]
      }
    ],
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "企业信息",
      icon: "iconfont icon-qiye",
      // icon: "el-icon-s-tools",
      path: "webset",
      keepAlive: false,

    },
    name: "企业信息",
    path: "/webset",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'webset/index',
        meta: {
          title: '企业信息',
        }
      },
    ]
  }
  
]
//3.司辅机构-运营
 const routersData3 = [
  {
    alwaysShow: true,

    //组件
    component: 'Layout',
    name: "资产管理",
    //路由地址
    path: "/property",
    //重定向
    redirect: "noredirect",
    meta: {
      title: "资产管理",
      icon: "el-icon-s-finance",
      path: "property",
      keepAlive: false,

    },
    children: [
      {
        path: 'index',
        component: 'property/index',
        meta: {
          title: '资产列表',
        }
      },
      {
        path: 'add',
        component: 'property/PropertyAdd',
        meta: {
          title: '新增资产',
        },
        children: [
          {
            path: 'slep1',
            component: 'property/components/add/AddStep1',
            meta: {
              title: '新增资产',
            }
          },
          {
            path: 'slep2',
            component: 'property/components/add/AddStep2',
            meta: {
              title: '新增资产',
            }
          },
          {
            path: 'slep3',
            component: 'property/components/add/AddStep3',
            meta: {
              title: '新增资产',
            }
          },
        ]
      },
      
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "视频管理",
      icon: "el-icon-video-camera-solid",
      path: "video",
      keepAlive: false,

    },
    name: "视频管理",
    path: "/video",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'video/index',
        meta: {
          title: '视频列表',
        }
      },
      {
        path: 'add',
        component: 'video/VideoInfo',
        meta: {
          title: '新增视频',
        }
      },
      {
        path: 'edit',
        component: 'video/VideoInfo',
        meta: {
          title: '编辑视频',
        }
      },
    ]

  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "锦鲲客流",
      // icon: "icon-gongxiang iconfont",
      // icon: "iconfont icon-gongxiang",
      icon: "el-icon-s-custom",
      path: "customer",
      keepAlive: false,

    },
    name: "锦鲲客流",
    path: "/customer",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'customer/index',
        meta: {
          title: '客户列表',
        }
      },
      {
        path: 'add',
        component: 'customer/CustomerAdd',
        meta: {
          title: '新增客户',
        }
      },
      {
        path: 'followup',
        component: 'customer/CustomerAdd',
        meta: {
          title: '客户跟进',
        }
      },
    ]
  },
  // {
  //   alwaysShow: true,
  //   component: 'Layout',
  //   meta: {
  //     title: "成员管理",
  //     icon: "el-icon-user-solid",
  //     path: "operator",
  //     keepAlive: false,

  //   },
  //   name: "成员管理",
  //   path: "/operator",
  //   redirect: "noredirect",
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'operator/index',
  //       meta: {
  //         title: '成员列表',
  //       }
  //     },
  //   ]
  // },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "业务共享",
      icon: "el-icon-share",
      path: "transaction",
      keepAlive: false,
    },
    name: "业务共享",
    path: "/transaction",
    redirect: "noredirect",
    children: [
      {
        component: 'LayoutSub',
        name: '业务报备',
        path: 'share',
        meta: {
          keepAlive: false,
          path: "share",
          title: '业务报备'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/shareproperty/index",
            meta: {
              keepAlive: false,
              title: '资产列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/shareproperty/BusinessReportInfo",
            meta: {
              keepAlive: false,
              title: '业务报备'
            },
          },
          {
            path: 'MyReport',
            component: "transactionshare/shareproperty/MyReport",
            meta: {
              keepAlive: false,
              title: '我的报备'
            },
          }
        ]
      },
       
      // {
      //   component: 'LayoutSub',


      //   name: '视频宣传',
      //   path: 'promotion',
      //   meta: {
      //     keepAlive: false,
      //     path: "promotion",
      //     title: '视频宣传'
      //   },
      //   children: [
      //     {
      //       // promotion
      //       path: 'index',
      //       component: "transactionshare/videopromotion/index",
      //       meta: {
      //         keepAlive: false,
      //         title: '视频宣传列表'
      //       },
      //     },
      //     {
      //       path: 'details',
      //       component: "transactionshare/videopromotion/VideoInfo",
      //       meta: {
      //         keepAlive: false,
      //         title: '视频宣传详情'
      //       },
      //     },
      //     {
      //       path: 'add',
      //       component: "transactionshare/videopromotion/PromotionInfo",
      //       meta: {
      //         keepAlive: false,
      //         title: '新增视频宣传'
      //       },
      //     },
      //     // {
      //     //   path: 'edit',
      //     //   component: "transactionshare/videopromotion/PromotionInfo",
      //     //   meta: {
      //     //     keepAlive: false,
      //     //     title: '编辑视频宣传'
      //     //   },
      //     // },
      //   ]
      // },
      {
        component: 'LayoutSub',
        name: '寻求资源',
        path: 'seek',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '寻求资源'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/seekresource/index",
            meta: {
              keepAlive: false,
              title: '寻求资源列表'
            },
          },
          {
            path: 'addseek',
            component: "transactionshare/seekresource/addseek",
            meta: {
              keepAlive: false,
              title: '新增寻求'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',
        name: '报告代理',
        path: 'agent',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '报告代理'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/reportagent/index",
            meta: {
              keepAlive: false,
              title: '报告代理列表'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',
        name: '回款统计',
        path: 'moenytotal',
        meta: {
          keepAlive: false,
          path: 'moenytotal',
          title: '回款统计'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/retmoneystatistics/index",
            meta: {
              keepAlive: false,
              title: '统计列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/retmoneystatistics/ReturnedMoneyInfo",
            meta: {
              keepAlive: false,
              title: '回款详情'
            },
          }
        ]
      }
    ],
  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "企业信息",
      icon: "iconfont icon-qiye",
      // icon: "el-icon-s-tools",
      path: "webset",
      keepAlive: false,

    },
    name: "企业信息",
    path: "/webset",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'webset/index',
        meta: {
          title: '企业信息',
        }
      },
    ]
  }
  
]
//4.中介机构-主账号
 const routersData4 = [
  // {
  //   alwaysShow: true,

  //   //组件
  //   component: 'Layout',
  //   name: "资产管理",
  //   //路由地址
  //   path: "/property",
  //   //重定向
  //   redirect: "noredirect",
  //   meta: {
  //     title: "资产管理",
  //     icon: "el-icon-s-finance",
  //     path: "property",
  //     keepAlive: false,

  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'property/index',
  //       meta: {
  //         title: '资产列表',
  //       }
  //     },
  //     {
  //       path: 'add',
  //       component: 'property/PropertyAdd',
  //       meta: {
  //         title: '新增资产',
  //       },
  //       children: [
  //         {
  //           path: 'slep1',
  //           component: 'property/components/add/AddStep1',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //         {
  //           path: 'slep2',
  //           component: 'property/components/add/AddStep2',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //         {
  //           path: 'slep3',
  //           component: 'property/components/add/AddStep3',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //       ]
  //     },
      
  //   ]
  // },
  // {
  //   alwaysShow: true,
  //   component: 'Layout',

  //   meta: {
  //     title: "视频管理",
  //     icon: "el-icon-video-camera-solid",
  //     path: "video",
  //     keepAlive: false,

  //   },
  //   name: "视频管理",
  //   path: "/video",
  //   redirect: "noredirect",
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'video/index',
  //       meta: {
  //         title: '视频列表',
  //       }
  //     },
  //     {
  //       path: 'add',
  //       component: 'video/VideoInfo',
  //       meta: {
  //         title: '新增视频',
  //       }
  //     },
  //     {
  //       path: 'edit',
  //       component: 'video/VideoInfo',
  //       meta: {
  //         title: '编辑视频',
  //       }
  //     },
  //   ]

  // },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "锦鲲客流",
      // icon: "icon-gongxiang iconfont",
      // icon: "iconfont icon-gongxiang",
      icon: "el-icon-s-custom",
      path: "customer",
      keepAlive: false,

    },
    name: "锦鲲客流",
    path: "/customer",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'customer/index',
        meta: {
          title: '客户列表',
        }
      },
      {
        path: 'add',
        component: 'customer/CustomerAdd',
        meta: {
          title: '新增客户',
        }
      },
      {
        path: 'followup',
        component: 'customer/CustomerAdd',
        meta: {
          title: '客户跟进',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "成员管理",
      icon: "el-icon-user-solid",
      path: "operator",
      keepAlive: false,

    },
    name: "成员管理",
    path: "/operator",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'operator/index',
        meta: {
          title: '成员列表',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "业务共享",
      icon: "el-icon-share",
      path: "transaction",
      keepAlive: false,
    },
    name: "业务共享",
    path: "/transaction",
    redirect: "noredirect",
    children: [
      {
        component: 'LayoutSub',
        name: '业务报备',
        path: 'share',
        meta: {
          keepAlive: false,
          path: "share",
          title: '业务报备'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/shareproperty/index",
            meta: {
              keepAlive: false,
              title: '资产列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/shareproperty/BusinessReportInfo",
            meta: {
              keepAlive: false,
              title: '业务报备'
            },
          },
          {
            path: 'MyReport',
            component: "transactionshare/shareproperty/MyReport",
            meta: {
              keepAlive: false,
              title: '我的业务报备'
            },
          }
        ]
      },       
      {
        component: 'LayoutSub',
        name: '视频宣传',
        path: 'promotion',
        meta: {
          keepAlive: false,
          path: "promotion",
          title: '视频宣传'
        },
        children: [
          {
            // promotion
            path: 'index',
            component: "transactionshare/videopromotion/index",
            meta: {
              keepAlive: false,
              title: '视频宣传列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/videopromotion/VideoInfo",
            meta: {
              keepAlive: false,
              title: '视频宣传详情'
            },
          },
          {
            path: 'add',
            component: "transactionshare/videopromotion/PromotionInfo",
            meta: {
              keepAlive: false,
              title: '新增视频宣传'
            },
          },
          {
            path: 'MyvdReport',
            component: "transactionshare/videopromotion/MyvdReport",
            meta: {
              keepAlive: false,
              title: '我的视频报备'
            },
          },
          {
            path: 'edit',
            component: "transactionshare/videopromotion/PromotionInfo",
            meta: {
              keepAlive: false,
              title: '编辑视频宣传'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',
        name: '寻求资源',
        path: 'seek',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '寻求资源'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/seekresource/index",
            meta: {
              keepAlive: false,
              title: '寻求资源列表'
            },
          },
          {
            path: 'addseek',
            component: "transactionshare/seekresource/addseek",
            meta: {
              keepAlive: false,
              title: '新增寻求'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',
        name: '报告代理',
        path: 'agent',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '报告代理'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/reportagent/index",
            meta: {
              keepAlive: false,
              title: '报告代理列表'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',


        name: '回款统计',
        path: 'moenytotal',
        meta: {
          keepAlive: false,
          path: 'moenytotal',
          title: '回款统计'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/retmoneystatistics/index",
            meta: {
              keepAlive: false,
              title: '统计列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/retmoneystatistics/ReturnedMoneyInfo",
            meta: {
              keepAlive: false,
              title: '回款详情'
            },
          }
        ]
      }
    ],
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "企业信息",
      icon: "iconfont icon-qiye",
      // icon: "el-icon-s-tools",
      path: "webset",
      keepAlive: false,

    },
    name: "企业信息",
    path: "/webset",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'webset/index',
        meta: {
          title: '企业信息',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "个人中心",
      icon: "el-icon-s-tools",
      path: "user",
      keepAlive: false,

    },
    name: "个人中心",
    path: "/user",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'user/index',
        meta: {
          title: '个人中心',
        }
      },
    ]
  },
]
//5.中介机构-管理员
 const routersData5 = [
  // {
  //   alwaysShow: true,

  //   //组件
  //   component: 'Layout',
  //   name: "资产管理",
  //   //路由地址
  //   path: "/property",
  //   //重定向
  //   redirect: "noredirect",
  //   meta: {
  //     title: "资产管理",
  //     icon: "el-icon-s-finance",
  //     path: "property",
  //     keepAlive: false,

  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'property/index',
  //       meta: {
  //         title: '资产列表',
  //       }
  //     },
  //     {
  //       path: 'add',
  //       component: 'property/PropertyAdd',
  //       meta: {
  //         title: '新增资产',
  //       },
  //       children: [
  //         {
  //           path: 'slep1',
  //           component: 'property/components/add/AddStep1',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //         {
  //           path: 'slep2',
  //           component: 'property/components/add/AddStep2',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //         {
  //           path: 'slep3',
  //           component: 'property/components/add/AddStep3',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //       ]
  //     },
      
  //   ]
  // },
  // {
  //   alwaysShow: true,
  //   component: 'Layout',

  //   meta: {
  //     title: "视频管理",
  //     icon: "el-icon-video-camera-solid",
  //     path: "video",
  //     keepAlive: false,

  //   },
  //   name: "视频管理",
  //   path: "/video",
  //   redirect: "noredirect",
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'video/index',
  //       meta: {
  //         title: '视频列表',
  //       }
  //     },
  //     {
  //       path: 'add',
  //       component: 'video/VideoInfo',
  //       meta: {
  //         title: '新增视频',
  //       }
  //     },
  //     {
  //       path: 'edit',
  //       component: 'video/VideoInfo',
  //       meta: {
  //         title: '编辑视频',
  //       }
  //     },
  //   ]

  // },
  // {
  //   alwaysShow: true,
  //   component: 'Layout',

  //   meta: {
  //     title: "锦鲲客流",
  //     // icon: "icon-gongxiang iconfont",
  //     // icon: "iconfont icon-gongxiang",
  //     icon: "el-icon-s-custom",
  //     path: "customer",
  //     keepAlive: false,

  //   },
  //   name: "锦鲲客流",
  //   path: "/customer",
  //   redirect: "noredirect",
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'customer/index',
  //       meta: {
  //         title: '客户列表',
  //       }
  //     },
  //     {
  //       path: 'add',
  //       component: 'customer/CustomerAdd',
  //       meta: {
  //         title: '新增客户',
  //       }
  //     },
  //     {
  //       path: 'followup',
  //       component: 'customer/CustomerAdd',
  //       meta: {
  //         title: '客户跟进',
  //       }
  //     },
  //   ]
  // },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "成员管理",
      icon: "el-icon-user-solid",
      path: "operator",
      keepAlive: false,

    },
    name: "成员管理",
    path: "/operator",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'operator/index',
        meta: {
          title: '成员列表',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "业务共享",
      icon: "el-icon-share",
      path: "transaction",
      keepAlive: false,
    },
    name: "业务共享",
    path: "/transaction",
    redirect: "noredirect",
    children: [
      {
        component: 'LayoutSub',
        name: '业务报备',
        path: 'share',
        meta: {
          keepAlive: false,
          path: "share",
          title: '业务报备'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/shareproperty/index",
            meta: {
              keepAlive: false,
              title: '资产列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/shareproperty/BusinessReportInfo",
            meta: {
              keepAlive: false,
              title: '业务报备'
            },
          },
          {
            path: 'MyReport',
            component: "transactionshare/shareproperty/MyReport",
            meta: {
              keepAlive: false,
              title: '我的报备'
            },
          }
        ]
      },
       
      {
        component: 'LayoutSub',


        name: '视频宣传',
        path: 'promotion',
        meta: {
          keepAlive: false,
          path: "promotion",
          title: '视频宣传'
        },
        children: [
          {
            // promotion
            path: 'index',
            component: "transactionshare/videopromotion/index",
            meta: {
              keepAlive: false,
              title: '视频宣传列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/videopromotion/VideoInfo",
            meta: {
              keepAlive: false,
              title: '视频宣传详情'
            },
          },
          {
            path: 'add',
            component: "transactionshare/videopromotion/PromotionInfo",
            meta: {
              keepAlive: false,
              title: '新增视频宣传'
            },
          },
          {
            path: 'MyvdReport',
            component: "transactionshare/videopromotion/MyvdReport",
            meta: {
              keepAlive: false,
              title: '我的视频报备'
            },
          }
          // {
          //   path: 'edit',
          //   component: "transactionshare/videopromotion/PromotionInfo",
          //   meta: {
          //     keepAlive: false,
          //     title: '编辑视频宣传'
          //   },
          // },
        ]
      },
      {
        component: 'LayoutSub',
        name: '寻求资源',
        path: 'seek',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '寻求资源'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/seekresource/index",
            meta: {
              keepAlive: false,
              title: '寻求资源列表'
            },
          },
          {
            path: 'addseek',
            component: "transactionshare/seekresource/addseek",
            meta: {
              keepAlive: false,
              title: '新增寻求'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',


        name: '报告代理',
        path: 'agent',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '报告代理'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/reportagent/index",
            meta: {
              keepAlive: false,
              title: '报告代理列表'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',


        name: '回款统计',
        path: 'moenytotal',
        meta: {
          keepAlive: false,
          path: 'moenytotal',
          title: '回款统计'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/retmoneystatistics/index",
            meta: {
              keepAlive: false,
              title: '统计列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/retmoneystatistics/ReturnedMoneyInfo",
            meta: {
              keepAlive: false,
              title: '回款详情'
            },
          }
        ]
      }
    ],
  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "企业信息",
      icon: "iconfont icon-qiye",
      // icon: "el-icon-s-tools",
      path: "webset",
      keepAlive: false,

    },
    name: "企业信息",
    path: "/webset",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'webset/index',
        meta: {
          title: '企业信息',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',
    meta: {
      title: "个人中心",
      icon: "el-icon-s-tools",
      path: "user",
      keepAlive: false,

    },
    name: "个人中心",
    path: "/user",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'user/index',
        meta: {
          title: '个人中心',
        }
      },
    ]
  },
]
//6.中介机构-运营
const routersData6 = [
  // {
  //   alwaysShow: true,

  //   //组件
  //   component: 'Layout',
  //   name: "资产管理",
  //   //路由地址
  //   path: "/property",
  //   //重定向
  //   redirect: "noredirect",
  //   meta: {
  //     title: "资产管理",
  //     icon: "el-icon-s-finance",
  //     path: "property",
  //     keepAlive: false,

  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'property/index',
  //       meta: {
  //         title: '资产列表',
  //       }
  //     },
  //     {
  //       path: 'add',
  //       component: 'property/PropertyAdd',
  //       meta: {
  //         title: '新增资产',
  //       },
  //       children: [
  //         {
  //           path: 'slep1',
  //           component: 'property/components/add/AddStep1',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //         {
  //           path: 'slep2',
  //           component: 'property/components/add/AddStep2',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //         {
  //           path: 'slep3',
  //           component: 'property/components/add/AddStep3',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //       ]
  //     },
      
  //   ]
  // },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "视频管理",
      icon: "el-icon-video-camera-solid",
      path: "video",
      keepAlive: false,

    },
    name: "视频管理",
    path: "/video",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'video/index',
        meta: {
          title: '视频列表',
        }
      },
      {
        path: 'add',
        component: 'video/VideoInfo',
        meta: {
          title: '新增视频',
        }
      },
      {
        path: 'edit',
        component: 'video/VideoInfo',
        meta: {
          title: '编辑视频',
        }
      },
    ]

  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "锦鲲客流",
      // icon: "icon-gongxiang iconfont",
      // icon: "iconfont icon-gongxiang",
      icon: "el-icon-s-custom",
      path: "customer",
      keepAlive: false,

    },
    name: "锦鲲客流",
    path: "/customer",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'customer/index',
        meta: {
          title: '客户列表',
        }
      },
      {
        path: 'add',
        component: 'customer/CustomerAdd',
        meta: {
          title: '新增客户',
        }
      },
      {
        path: 'followup',
        component: 'customer/CustomerAdd',
        meta: {
          title: '客户跟进',
        }
      },
    ]
  },
  // {
  //   alwaysShow: true,
  //   component: 'Layout',
  //   meta: {
  //     title: "成员管理",
  //     icon: "el-icon-user-solid",
  //     path: "operator",
  //     keepAlive: false,

  //   },
  //   name: "成员管理",
  //   path: "/operator",
  //   redirect: "noredirect",
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'operator/index',
  //       meta: {
  //         title: '成员列表',
  //       }
  //     },
  //   ]
  // },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "业务共享",
      icon: "el-icon-share",
      path: "transaction",
      keepAlive: false,
    },
    name: "业务共享",
    path: "/transaction",
    redirect: "noredirect",
    children: [
      {
        component: 'LayoutSub',
        name: '业务报备',
        path: 'share',
        meta: {
          keepAlive: false,
          path: "share",
          title: '业务报备'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/shareproperty/index",
            meta: {
              keepAlive: false,
              title: '资产列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/shareproperty/BusinessReportInfo",
            meta: {
              keepAlive: false,
              title: '业务报备'
            },
          },
          {
            path: 'MyReport',
            component: "transactionshare/shareproperty/MyReport",
            meta: {
              keepAlive: false,
              title: '我的报备'
            },
          }
        ]
      },
       
      {
        component: 'LayoutSub',
        name: '视频宣传',
        path: 'promotion',
        meta: {
          keepAlive: false,
          path: "promotion",
          title: '视频宣传'
        },
        children: [
          {
            // promotion
            path: 'index',
            component: "transactionshare/videopromotion/index",
            meta: {
              keepAlive: false,
              title: '视频宣传列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/videopromotion/VideoInfo",
            meta: {
              keepAlive: false,
              title: '视频宣传详情'
            },
          },
          {
            path: 'add',
            component: "transactionshare/videopromotion/PromotionInfo",
            meta: {
              keepAlive: false,
              title: '新增视频宣传'
            },
          },
          {
            path: 'MyvdReport',
            component: "transactionshare/videopromotion/MyvdReport",
            meta: {
              keepAlive: false,
              title: '我的视频报备'
            },
          }
          // {
          //   path: 'edit',
          //   component: "transactionshare/videopromotion/PromotionInfo",
          //   meta: {
          //     keepAlive: false,
          //     title: '编辑视频宣传'
          //   },
          // },
        ]
      },
      {
        component: 'LayoutSub',
        name: '寻求资源',
        path: 'seek',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '寻求资源'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/seekresource/index",
            meta: {
              keepAlive: false,
              title: '寻求资源列表'
            },
          },
          {
            path: 'addseek',
            component: "transactionshare/seekresource/addseek",
            meta: {
              keepAlive: false,
              title: '新增寻求'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',
        name: '报告代理',
        path: 'agent',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '报告代理'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/reportagent/index",
            meta: {
              keepAlive: false,
              title: '报告代理列表'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',
        name: '回款统计',
        path: 'moenytotal',
        meta: {
          keepAlive: false,
          path: 'moenytotal',
          title: '回款统计'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/retmoneystatistics/index",
            meta: {
              keepAlive: false,
              title: '统计列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/retmoneystatistics/ReturnedMoneyInfo",
            meta: {
              keepAlive: false,
              title: '回款详情'
            },
          }
        ]
      }
    ],
  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "企业信息",
      icon: "iconfont icon-qiye",
      // icon: "el-icon-s-tools",
      path: "webset",
      keepAlive: false,

    },
    name: "企业信息",
    path: "/webset",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'webset/index',
        meta: {
          title: '企业信息',
        }
      },
    ]
  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "个人中心",
      icon: "el-icon-s-tools",
      path: "user",
      keepAlive: false,

    },
    name: "个人中心",
    path: "/user",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'user/index',
        meta: {
          title: '个人中心',
        }
      },
    ]
  },
]
//7.个人推荐官
const routersData7 = [
  // {
  //   alwaysShow: true,

  //   //组件
  //   component: 'Layout',
  //   name: "资产管理",
  //   //路由地址
  //   path: "/property",
  //   //重定向
  //   redirect: "noredirect",
  //   meta: {
  //     title: "资产管理",
  //     icon: "el-icon-s-finance",
  //     path: "property",
  //     keepAlive: false,

  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'property/index',
  //       meta: {
  //         title: '资产列表',
  //       }
  //     },
  //     {
  //       path: 'add',
  //       component: 'property/PropertyAdd',
  //       meta: {
  //         title: '新增资产',
  //       },
  //       children: [
  //         {
  //           path: 'slep1',
  //           component: 'property/components/add/AddStep1',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //         {
  //           path: 'slep2',
  //           component: 'property/components/add/AddStep2',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //         {
  //           path: 'slep3',
  //           component: 'property/components/add/AddStep3',
  //           meta: {
  //             title: '新增资产',
  //           }
  //         },
  //       ]
  //     },
      
  //   ]
  // },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "视频管理",
      icon: "el-icon-video-camera-solid",
      path: "video",
      keepAlive: false,

    },
    name: "视频管理",
    path: "/video",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'video/index',
        meta: {
          title: '视频列表',
        }
      },
      {
        path: 'add',
        component: 'video/VideoInfo',
        meta: {
          title: '新增视频',
        }
      },
      {
        path: 'edit',
        component: 'video/VideoInfo',
        meta: {
          title: '编辑视频',
        }
      },
    ]

  },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "客户需求",
      // icon: "icon-gongxiang iconfont",
      // icon: "iconfont icon-gongxiang",
      icon: "el-icon-s-custom",
      path: "customer",
      keepAlive: false,

    },
    name: "客户需求",
    path: "/customer",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'customer/index',
        meta: {
          title: '客户列表',
        }
      },
      {
        path: 'add',
        component: 'customer/CustomerAdd',
        meta: {
          title: '新增客户',
        }
      },
      {
        path: 'followup',
        component: 'customer/CustomerAdd',
        meta: {
          title: '客户跟进',
        }
      },
    ]
  },
  // {
  //   alwaysShow: true,
  //   component: 'Layout',
  //   meta: {
  //     title: "成员管理",
  //     icon: "el-icon-user-solid",
  //     path: "operator",
  //     keepAlive: false,

  //   },
  //   name: "成员管理",
  //   path: "/operator",
  //   redirect: "noredirect",
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'operator/index',
  //       meta: {
  //         title: '成员列表',
  //       }
  //     },
  //   ]
  // },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "业务共享",
      icon: "el-icon-share",
      path: "transaction",
      keepAlive: false,
    },
    name: "业务共享",
    path: "/transaction",
    redirect: "noredirect",
    children: [
      {
        component: 'LayoutSub',
        name: '业务报备',
        path: 'share',
        meta: {
          keepAlive: false,
          path: "share",
          title: '业务报备'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/shareproperty/index",
            meta: {
              keepAlive: false,
              title: '资产列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/shareproperty/BusinessReportInfo",
            meta: {
              keepAlive: false,
              title: '业务报备'
            },
          },
          {
            path: 'MyReport',
            component: "transactionshare/shareproperty/MyReport",
            meta: {
              keepAlive: false,
              title: '我的报备'
            },
          }
        ]
      },
       
      {
        component: 'LayoutSub',
        name: '视频宣传',
        path: 'promotion',
        meta: {
          keepAlive: false,
          path: "promotion",
          title: '视频宣传'
        },
        children: [
          {
            // promotion
            path: 'index',
            component: "transactionshare/videopromotion/index",
            meta: {
              keepAlive: false,
              title: '视频宣传列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/videopromotion/VideoInfo",
            meta: {
              keepAlive: false,
              title: '视频宣传详情'
            },
          },
          {
            path: 'add',
            component: "transactionshare/videopromotion/PromotionInfo",
            meta: {
              keepAlive: false,
              title: '新增视频宣传'
            },
          },
          {
            path: 'MyvdReport',
            component: "transactionshare/videopromotion/MyvdReport",
            meta: {
              keepAlive: false,
              title: '我的视频报备'
            },
          }
          // {
          //   path: 'edit',
          //   component: "transactionshare/videopromotion/PromotionInfo",
          //   meta: {
          //     keepAlive: false,
          //     title: '编辑视频宣传'
          //   },
          // },
        ]
      },
      {
        component: 'LayoutSub',
        name: '寻求资源',
        path: 'seek',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '寻求资源'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/seekresource/index",
            meta: {
              keepAlive: false,
              title: '寻求资源列表'
            },
          },
          {
            path: 'addseek',
            component: "transactionshare/seekresource/addseek",
            meta: {
              keepAlive: false,
              title: '新增寻求'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',


        name: '报告代理',
        path: 'agent',
        meta: {
          keepAlive: false,
          path: "agent",
          title: '报告代理'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/reportagent/index",
            meta: {
              keepAlive: false,
              title: '报告代理列表'
            },
          },
        ]
      },
      {
        component: 'LayoutSub',


        name: '回款统计',
        path: 'moenytotal',
        meta: {
          keepAlive: false,
          path: 'moenytotal',
          title: '回款统计'
        },
        children: [
          {
            path: 'index',
            component: "transactionshare/retmoneystatistics/index",
            meta: {
              keepAlive: false,
              title: '统计列表'
            },
          },
          {
            path: 'details',
            component: "transactionshare/retmoneystatistics/ReturnedMoneyInfo",
            meta: {
              keepAlive: false,
              title: '回款详情'
            },
          }
        ]
      }
    ],
  },
  // {
  //   alwaysShow: true,
  //   component: 'Layout',
  //   meta: {
  //     title: "企业信息",
  //     icon: "iconfont icon-qiye",
  //     // icon: "el-icon-s-tools",
  //     path: "webset",
  //     keepAlive: false,

  //   },
  //   name: "企业信息",
  //   path: "/webset",
  //   redirect: "noredirect",
  //   children: [
  //     {
  //       path: 'index',
  //       component: 'webset/index',
  //       meta: {
  //         title: '企业信息',
  //       }
  //     },
  //   ]
  // },
  {
    alwaysShow: true,
    component: 'Layout',

    meta: {
      title: "个人中心",
      icon: "el-icon-s-tools",
      path: "user",
      keepAlive: false,

    },
    name: "个人中心",
    path: "/user",
    redirect: "noredirect",
    children: [
      {
        path: 'index',
        component: 'user/index',
        meta: {
          title: '个人中心',
        }
      },
    ]
  },
]
//0.空
export const routersData0 = []

export function setMenu(res){
  if(res.Type === 1){
    return routersData1
  }else if(res.Type === 2){
    return routersData2
  }else if(res.Type === 3){
    return routersData3
  }else if(res.Type === 4){
    return routersData4
  }else if(res.Type === 5){
    return routersData5
  }else if(res.Type === 6){
    return routersData6
  }else if(res.Type === 7){
    return routersData7
  }else{
    return routersData0
  }
};