import formUtils from './formUtils';

const appData = {
  //蓝色，绿色，黄色，红色，灰色
  textColor: {
    blue: "#409eff",
    green: "#67C23A",
    yellow: "#e6a23c",
    red: "#D73240",
    gray: "#666666"
  },
  backColor: {
    blue: "#409eff",
    green: "#f0f9eb",
    yellow: "#e6a23c",
    red: "#D73240",
    gray: "#DCDFE6"
  },
  // textColor: ["#409eff","#67C23A","#e6a23c","#D73240","#666666"],
}; 
export default {
  appData,
  formUtils
}
