<template>
  <div>
    <!-- :key="$route.fullPath" 用来区分新增和编辑页，不然这两个页面跳转不刷新 -->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
</style>