

const user = {
  state: {
   
  },

  mutations: {
  },

  actions: {
    // 登录
   
  }
}


export default user
