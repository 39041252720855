// import router from './routers'
// import NProgress from 'nprogress' // progress bar


// router.afterEach((to) => {
//   console.log(to);
//   NProgress.done() // finish progress bar
// })
import router from './routers'
import store from '@/store'
// import Config from '@/settings'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style
// import { getToken } from '@/utils/auth' // getToken from cookie
// import { getUserMenus } from '@/api/system/menus'
import { filterAsyncRouter } from '@/store/modules/routers'

NProgress.configure({ showSpinner: false })



// const whiteList = ['/login']

// router.beforeEach((to, from, next) => {

//   if (to.matched.length && to.matched[0].meta && to.matched[0].meta.title) {
//     document.title = to.matched[0].meta.title + ' - 司辅机构后台';
//   } else {
//     document.title = "司辅机构后台";

//   }
//   NProgress.start()
//   next()
//   function fun() {
//     //！！！！ rewriteRoutes 替换成 store.state.router.路由变量名
//     rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
//     rewriteRoutes.forEach(item => {
//       router.addRoute(item) // 动态添加可访问路由表
//       // next({ ...to, replace: true })
//     });
//   }
//   // if (getToken()) {
//   //   // 已登录且要跳转的页面是登录页
//   //   if (to.path === '/login') {
//   //     next({ path: '/' })
//   //     NProgress.done()
//   //   } else {
//   //     if (store.getters.userInfo.length === 0) { // 判断当前用户是否已拉取完user_info信息
//   //       store.dispatch('GetUserInfo').then(() => { // 拉取user_info
//   //         // 动态路由，拉取菜单
//   //         loadMenus(next, to)
//   //       }).catch(() => {
//   //         store.dispatch('LogOut').then(() => {
//   //           location.reload() // 为了重新实例化vue-router对象 避免bug
//   //         })
//   //       })
//   //       // 登录时未拉取 菜单，在此处拉取
//   //     } else if (store.getters.loadMenus) {
//   //       // 修改成false，防止死循环
//   //       store.dispatch('updateLoadMenus')
//   //       loadMenus(next, to)
//   //     } else {
//   //       next()
//   //     }
//   //   }
//   // } else {
//   //   /* has no token*/
//   //   if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
//   //     next()
//   //   } else {
//   //     next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
//   //     NProgress.done()
//   //   }
//   // }
// })

export function loadMenus2(val) {
  //!!!!必须使用json，进行深拷贝！！！！
  // const sdata = JSON.parse(JSON.stringify(routersData))
  // const rdata = JSON.parse(JSON.stringify(routersData))
  
  const sdata = JSON.parse(JSON.stringify(val))
  const rdata = JSON.parse(JSON.stringify(val))
  const sidebarRoutes = filterAsyncRouter(sdata, 2)
  const rewriteRoutes = filterAsyncRouter(rdata)
  // path: '*' 表示任意路径
  rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })

  store.dispatch('GenerateRoutes', rewriteRoutes).then(() => { // 存储路由
    rewriteRoutes.forEach(item => {
      router.addRoute(item) // 动态添加可访问路由表
        //next({ ...to, replace: true })
    });
    router.push("/");
  })
  // store存储菜单栏
  store.dispatch('SetAsideMenuRouters', sidebarRoutes)
  //路由缓存
  sessionStorage.setItem("rewriteRoutes", JSON.stringify(rewriteRoutes));
  

   //菜单栏缓存
  sessionStorage.setItem("sidebarRoutes", JSON.stringify(sidebarRoutes));
  
  
}

export function loadMenus (val){
  //!!!!必须使用json，进行深拷贝！！！！
  // const sdata = JSON.parse(JSON.stringify(routersData))
  // const rdata = JSON.parse(JSON.stringify(routersData))
  
  const sdata = JSON.parse(JSON.stringify(val))
  const rdata = JSON.parse(JSON.stringify(val))
  const sidebarRoutes = filterAsyncRouter(sdata, 2)
  const rewriteRoutes = filterAsyncRouter(rdata)
  // path: '*' 表示任意路径
  rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })

  store.dispatch('GenerateRoutes', rewriteRoutes).then(() => { // 存储路由
    rewriteRoutes.forEach(item => {
      router.addRoute(item) // 动态添加可访问路由表
        //next({ ...to, replace: true })
    });
  })
  // store存储菜单栏
  store.dispatch('SetAsideMenuRouters', sidebarRoutes)
  //路由缓存
  sessionStorage.setItem("rewriteRoutes", JSON.stringify(rewriteRoutes));
 

   //菜单栏缓存
  sessionStorage.setItem("sidebarRoutes", JSON.stringify(sidebarRoutes));
  
}



router.afterEach((to) => {
  NProgress.done() // finish progress bar
})
