import axios from 'axios' 
import { Notification, MessageBox } from 'element-ui'
import { getToken, setToken } from '@/utils/auth'
import Cookies from 'js-cookie';
const service = axios.create({
  // api 的 base_url
  baseURL: "https://st.api.jinkunzibo.com/api",
  //baseURL: "http://localhost:51928/api", 
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
  // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    const token = Cookies.get('token')
    if(token!==''&&token!==null){
      config.headers['Token'] =token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    // removeRepeatUrl(response.config)

    // 延续 Token 时间
    const SysToken = getToken()
    if (SysToken) {
      setToken(SysToken)
    }
    if (response.status !== 200) { 
      switch (response.status) {
        case 401:
          MessageBox.confirm(
            '登录状态已过期，您可以继续留在该页面，或者重新登录',
            '系统提示',
            {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).then(() => {
            Cookies.remove('token')
            //清除userInfo
            sessionStorage.removeItem("userInfo");
            //清除路由缓存
            sessionStorage.removeItem("rewriteRoutes");
            //清除菜单栏缓存
            sessionStorage.removeItem("sidebarRoutes");
          })
          break
        case 403:
          Notification.error({
            title: '您无权进行此操作，请求执行已拒绝',
            duration: 3000
          })
          break
        default:
          Notification.error({
            title: response.status
          })
          break
      }
    }

    return response.data
  },
  error => {
    var res = error.response;
    
      switch (res.status) {
        case 401:
          MessageBox.confirm(
            '登录状态已过期，您可以继续留在该页面，或者重新登录',
            '系统提示',
            {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).then(() => {
            Cookies.remove('token')
            //清除userInfo
            sessionStorage.removeItem("userInfo");
            //清除路由缓存
            sessionStorage.removeItem("rewriteRoutes");
            //清除菜单栏缓存
            sessionStorage.removeItem("sidebarRoutes");
          })
          break
        case 403:
          Notification.error({
            title: '您无权进行此操作，请求执行已拒绝',
            duration: 3000
          })
          break
        default:
           Notification.error({
            title: '请求接口失败',
            duration: 3000
          })
          break
      }
    
   
    return Promise.reject(error)
  }
)
export default service
