<template>
  <!-- 首页 -->
  <div class="home">
    <div class="info-aggregation">
      <div class="info-img">
        <img :src="Imgurl"/>
      </div>
      <div class="info-content">
        <div class="corporate-name">{{InfoDate.Name}}</div>

        <div class="info-items">
          <div>
            <span>资粉：</span>
            <span>{{InfoDate.GZCount}}</span>
          </div>
          <div v-if="this.userinfo.OrgType ==1">
            <span>锦鲲客流：</span>
            <span>{{InfoDate.Passenger}}</span>
          </div>
          <div>
            <span>预计总收益：</span>
            <span>{{InfoDate.TotalRevenue}}</span>
            <span>元</span>
          </div>
          <div>
            <span>实际收益：{{InfoDate.ReturnedMoney+InfoDate.InReceipt}}元（</span>
            <span>回款中：</span>
            <span>{{InfoDate.InReceipt}}</span>
            <span>元</span>
            <span>、</span>
            <span>已回款：</span>
            <span>{{InfoDate.ReturnedMoney}}</span>
            <span>元)</span>
          </div>
        </div>
      </div>
    </div>

    <div class="shortcuts-btn">
      <div class="card-title">
        <span>快捷操作</span>
      </div>
      <div class="shortcuts-content">
        <div class="content-item" @click="handleShortcuts(2)"  v-if="this.userinfo.OrgType ==1">
          <i class="el-icon-office-building"></i>
          <span>上传资产</span>
        </div>
        <div class="content-item" @click="handleShortcuts(1)" v-if="this.userinfo.Type !=4">
          <!-- <img src="@/assets/images/video-icon.png" alt="" /> -->
          <i class="iconfont icon-shipin"></i>
          <span>上传视频</span>
        </div>
        <div class="content-item" @click="handleShortcuts(3)" v-if="this.userinfo.Type ==1 ||this.userinfo.Type ==2||this.userinfo.Type ==4||this.userinfo.Type ==5">
          <i class="el-icon-user"></i>
          <span>成员管理</span>
        </div>
        <div class="content-item" @click="handleShortcuts(4)">
          <i class="iconfont icon-gongxiang"></i>
          <span>共享报备</span>
        </div>
      </div>
    </div>

    <div class="yest-target">
      <div class="card-title">
        <div class="card-title-text">
          <span>关键指标</span>
          <span class="card-title-desc">(按月分析)</span>
        </div>
        <div class="radio-box">
          <span class="title-label">分析参数：</span>
          <el-radio-group v-model="radio1" @change="radio1change">
            <el-radio :label="1">月</el-radio>
            <el-radio :label="2">周</el-radio>
            <el-radio :label="3">日</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="card-content">
        <div class="card-content-item" v-if="this.userinfo.OrgType ==1">
          <div class="item-title">
            <span>总上架资产</span>
          </div>
          <div class="item-info">
            <span>{{TargeDate.AssetSum}}</span>
            <div class="rise-num">
              <i class="iconfont icon-shangsheng"></i>
              <span>{{TargeDate.AssetNum}}</span>
            </div>
          </div>
        </div>
        <div class="card-content-item">
          <div class="item-title">
            <span>总上架视频</span>
          </div>
          <div class="item-info">
            <span>{{TargeDate.VideoSum}}</span>
            <div class="rise-num">
              <i class="iconfont icon-shangsheng"></i>
              <span>{{ TargeDate.VideoNum }}</span>
            </div>
          </div>
        </div>
        <div class="card-content-item" v-if="this.userinfo.OrgType ==1">
          <div class="item-title">
            <span>总成交金额</span>
          </div>
          <div class="item-info" style="width: 280px;">
            <span>{{ TargeDate.PriceSum }}</span>
            <span>万</span>
            <div class="rise-num">
              <i class="iconfont icon-shangsheng"></i>
              <span>{{ TargeDate.PriceNum }}</span>
            </div>
          </div>
        </div>
        <div class="card-content-item">
          <div class="item-title">
            <span>总浏览量</span>
            <i class="el-icon-question"></i>
          </div>
          <div class="item-info" style="width: 260px;">
            <span>{{TargeDate.BrowseSum}}</span>
            <span>万</span>
            <div class="rise-num">
              <i class="iconfont icon-shangsheng"></i>
              <span>{{TargeDate.BrowseNum}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="target-ana">
      <div class="card-title">
        <span>关键指标分析</span>
        <div class="date-box">
          <!-- <span class="title-label">时间范围：</span> -->
          <el-date-picker
            v-model="dateRange"
            type="year"
            @change="dateRangeChange"
            value-format="yyyy"
          >
          </el-date-picker>
        </div>
        <div class="radio-box">
          <el-radio-group v-model="radio2" @change="radio2change">
            <el-radio :label="1" v-if="this.userinfo.OrgType ==1">上架资产</el-radio>
            <el-radio :label="2">上架视频</el-radio>
            <el-radio :label="3" v-if="this.userinfo.OrgType ==1">成交金额</el-radio>
            <el-radio :label="4">浏览量</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div><div id="linechart1" style="height: 350px"></div></div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%">
      <span>平台账号信息未填写，点击跳转到填写信息界面</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tiaozhaun()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      width="30%">
      <span>机构推荐官数量不足3个，点击跳转到添加推荐官界面</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tiaozhauntjg()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LineChart from "./components/LineChart.vue";
import {GetTargetList,GetTargetTrendInfo,HomeGetInfo,getUserInfo,GetDealAccountInfo,GetLivePersonsInfo} from '../../api/apis.js'
export default {
  name: "HomeView",
  components: {
    LineChart,
  },
  data() {
    return {
      itemShow: true,
      Imgurl:'',
      dialogVisible1:false,
      dialogVisible:false,
      DGaccount:'',
      DSaccount:'',
      riseNums: [6, 7, 8],
      TargeDate:[],
      InfoDate:[],
      dateRange: "",
      radio1: 1,
      radio2: 1,
      userinfo:{},
      listData:[],
      CorporateAccount:false,//对公账号状态
      PersonalAccount:false,//个人账号状态
      TargetParam:{
        DateType:1,
        Type:'',
        YeatDate:'',
      },
      TargetTrendParam:{
        DateType:'',
        Type:'',
        YeatDate:'',
      },     
    };
  },
  created() {  
    this.getInfo();
    this.getUser(this.getToken('token'));
    this.TargetInfo(this.TargetParam);
  },
  methods: {
    getUser(token){
      //判断用户类型,存入store中
      getUserInfo(token).then(res=>{
        this.userinfo=res;
        if(this.userinfo.Type==4 || this.userinfo.Type==6 ||this.userinfo.Type==7){
          this.getbankinfro(res.AccountId,2);//个人
        }
        this.TargetTrendParam.YeatDate=new Date().getFullYear();
        this.dateRange=new Date().getFullYear().toString();
        if(this.userinfo.OrgType==1){
          this.TargetTrendParam.Type=1;
          this.TargetTrendInfo(this.TargetTrendParam);
        }else{
          this.TargetTrendParam.Type=2;
          this.radio2=2;
          this.TargetTrendInfo(this.TargetTrendParam);
        }
      })
    },
    getToken(a){
        var d;
        var b = document.cookie;
        var c = b.split(";");
        for (let e = 0; e < c.length; e++) {
          var f = c[e].split("=");
          if (a == f[0].toString().trim()) {
            d = f[1];
            break;
          }
        } if (void 0 == d || null == d) {
          return "";
        }
        else {
          var g = unescape(d.trim());
          return g;      
      }
    },
    //获取回款信息
    getbankinfro(aid,type) {
      GetDealAccountInfo({id:aid}).then((res) => {
        if (res.IsSuccess) {
          if(type==1){
              this.DGaccount=res.ResultObject.No;
              if(res.ResultObject.No=="" || res.ResultObject.No==null){
                this.CorporateAccount=true;
                this.dialogVisible=true;              
              } 
            }else if(type==2){
              this.DGaccount=res.ResultObject.No;
              if(res.ResultObject.No=="" || res.ResultObject.No==null){
                this.PersonalAccount=true;
                this.dialogVisible=true;  
              } 
            }                     
        } else {
          this.$message.error(res1.Msg);
        }
      });
    },
    //跳转
    tiaozhaun(){
      //司辅 只有对公
      if(this.userinfo.OrgType==1){
        this.$router.push("/webset/index");
      }else if(this.userinfo.OrgType==2 && this.userinfo.Type==4 ){ //中介主   
        if(this.CorporateAccount==true && this.PersonalAccount==true){
          console.log(1)
          this.$router.push("/webset/index"); 
        }else if(this.CorporateAccount==true && this.PersonalAccount==false){
          console.log(2)
          this.$router.push("/webset/index"); 
        }else{
          console.log(3)
          this.$router.push("/user/index");
        }
      }else{ //推荐官只有对私
        this.$router.push("/user/index");
      }     
    },
    //跳转到推荐官新增页面
    tiaozhauntjg(){
      this.$router.push("/operator/index");
    },
    //机构基础信息
    getInfo(){
      HomeGetInfo().then(res=>{
        if(res.IsSuccess){
          this.InfoDate=res.ResultObject;
          if(res.ResultObject.MarketCount<3 &&this.userinfo.Type==4){
            if(this.dialogVisible==false){
              this.dialogVisible1=true;
            }
          }
          if(this.userinfo.OrgType==1 ||this.userinfo.Type==4){
            this.getbankinfro(this.InfoDate.DealAccountID,1);//公司账号
          }      
          if(this.userinfo.OrgType==1 ||this.userinfo.Type==4){
            this.Imgurl = 'https://cdn.jinkunzibo.com/UpLoadFiles'+this.InfoDate.Imgurl;
          }else{          
            GetLivePersonsInfo({id:this.userinfo.LiveId}).then((res) => {
              if(res.IsSuccess){      
                this.Imgurl = 'https://cdn.jinkunzibo.com/UpLoadFiles'+ res.ResultObject.HeadPortrait;       
              }
            })
          }    
        }
      });
    },
    drawChart() {
      let lineChart = this.$echarts.init(
        document.getElementById("linechart1")
      );
      let option = {
        grid: {
          left: "10%",
          right: "5%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["一月","二月","三月","四月","五月","六月","七月","八月","九月", "十月", "十一月", "十二月"],
          axisLabel:
          {
            rotate:-45,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data:this.listData,
            type: "line",
            symbol: 'circle',
            symbolSize: 6,
            lineStyle: {
              color: "#1890FF",           
            },
            //背景
            areaStyle: {
              color: "#E7F4FF"
            },
            itemStyle: {
              color: '#1890FF',
              borderColor: "#fff",
            }
          },
        ],
      };
      option && lineChart.setOption(option);
    },
    //关键指标(按月分析)
    TargetInfo(param){
      GetTargetList(param).then(res=>{
        if(res.IsSuccess){
          this.TargeDate=res.ResultObject;
        }
      });
    },
    //关键指标(按月分析)单选框值更新事件 
    radio1change(val) {
      this.TargetParam.DateType=val;
      this.TargetInfo(this.TargetParam);
    }, 
    //关键指标趋势图
    TargetTrendInfo(param){
      GetTargetTrendInfo(param).then(res=>{
        if(res.IsSuccess){
          this.listData=[res.ResultObject.One,res.ResultObject.Two,res.ResultObject.There,res.ResultObject.Four,res.ResultObject.Five,res.ResultObject.Six,
        res.ResultObject.Seven,res.ResultObject.Eight,res.ResultObject.Nine,res.ResultObject.Ten,res.ResultObject.Eleven,res.ResultObject.Twelve];
          this.drawChart();
        }
      });
    },
    //关键指标趋势图单选框值更新事件
    radio2change(val){
      this.TargetTrendParam.Type=val;
      this.TargetTrendInfo(this.TargetTrendParam);
    },
    handleShortcuts(type) {
      if (type == 1) {
        // console.log("上传视频");
        this.$router.push("/video/add");
      } else if (type == 2) {
        // console.log("上传资产");
        this.$router.push("/property/add");
      } else if (type == 3) {
        // console.log("成员管理");
        this.$router.push("/operator/index");
      } else {
        this.$router.push("/transaction/share/index");
      }
    },
    //年份改变事件
    dateRangeChange(date) {
      if(date != null ){
        this.TargetTrendParam.YeatDate=date;
        this.TargetTrendInfo(this.TargetTrendParam);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.info-aggregation {
  position: relative;
  height: 60px;
  margin-bottom: 30px;
  // background-color: #fff;
  .info-img {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: 114%;
    background-position: -3px -7px;
    box-shadow: 1px 1px 6px #ddd;
    overflow: hidden;
    img{
      width: 60px;
      height: 60px;
    }
  }
  .info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 76px;
  }
  .corporate-name {
    display: inline-block;
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    // vertical-align: top;
  }

  .info-items {
    margin-top: 14px;
    color: #666666;
    font-size: 14px;
    div {
      display: inline-block;
      margin-right: 50px;
    }
  }
}
.shortcuts-btn,
.yest-target,
.target-ana {
  margin-bottom: 20px;
  background-color: #fff;
}
//快捷操作
.shortcuts-btn {
  height: 276px;
  .shortcuts-content {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    .content-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 180px;
      background: #fff;
      border: 1px dashed #e7e7eb;
      color: #666666;

      // text-align: center;
      cursor: pointer;
      &:hover {
        background: #f6f7f8;
        color: #409eff;
      }
      i {
        font-size: 32px;
      }
      img {
        width: 32px;
        height: 32px;
      }
      span {
        padding-top: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
      }
      // &:nth-child(1) {
      //   img
      // }
    }
  }
}

//昨日关键指标
.yest-target {
  height: 192px;
  .card-content {
    display: flex;
    // justify-content: center;
    justify-content: space-around;
    .card-content-item {
      width: 320px;
      height: 116px;
      margin-top: 16px;
      // border: 1px solid #000;
      text-align: center;
      color: $deepGray;

      .item-title {
        font-size: 14px;
        i {
          margin-left: 5px;
          color: #999999;
          cursor: pointer;
        }
      }
      .item-info {
        margin-top: 14px;
        // 数字
        > span:nth-child(1) {
          font-size: 48px;
        }
        // 单位
        > span:nth-child(2) {
          font-size: 14px;
          margin-left: 3px;
        }
        .rise-num {
          display: inline-block;
          height: 48px;
          margin-left: 10px;
          color: #f53c3c;
          i {
            margin-right: 3px;
            font-size: 20px;
          }

          vertical-align: middle;
        }
      }
    }
  }
}
.target-ana {
  height: 430px;
}
.card-title {
  display: flex;
  justify-content: space-between;
  height: 60px;
  // padding: 15px 20px 0;
  padding: 0 20px 0;
  line-height: 60px;
  border-bottom: 1px solid #ebeef5;

  font-size: 20px;
  color: $deepGray;
  .card-title-desc {
    font-size: 14px;
  }
  .date-box {
    // display: inline-block;
    width: 300px;
  }
  // .radio-box {

  // }
  .title-label {
    margin-right: 5px;
    font-size: 14px;
    color: $lightGray;
  }
}
.card-content-flex {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}
.card1-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 180px;
  background: #fff;
  border: 1px solid #e7e7eb;
  color: #666666;

  // text-align: center;
  cursor: pointer;
  &:hover {
    background: #f6f7f8;
    color: #409eff;
  }
  i {
    font-size: 30px;
  }
  img {
    width: 32px;
    height: 32px;
  }
  span {
    padding-top: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
  }
  // &:nth-child(1) {
  //   img
  // }
}
</style>