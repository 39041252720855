import { render, staticRenderFns } from "./sub.vue?vue&type=template&id=ef20e5ae&scoped=true"
import script from "./sub.vue?vue&type=script&lang=js"
export * from "./sub.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef20e5ae",
  null
  
)

export default component.exports